import React, { useState, useEffect } from 'react'; 
import {
    updateDebate,  
    setDebateFormat, 
    updateDebateFormat, 
    getDebateFormatInfo } from '../api/debate'
import Loader from 'react-loader-spinner';
import PageLoader from 'react-loader-advanced'
import moment from 'moment'

const timings = {
    "debate_format_constructive_A":3, 
    "debate_format_constructive_B":5,
    "debate_format_crossfire1_AB":3, 
    "debate_format_rebuttal_A":2, 
    "debate_format_rebuttal_B":5, 
    "debate_format_crossfire2_AB":0, 
    "debate_format_summary":0, 
    "debate_format_grand_crossfire_AB": 0, 
    "debate_format_final_focus_A":0, 
    "debate_format_final_focus_B":0  
}

function CustomDebateTable (props){
    const [ customTimings, setCustomTimings ] = useState({...timings})
    const [ values, setvalue] = useState([])
    const [ loading, setLoading] = useState(false)
    const [ formatID, setFromatID ] = useState('')
    const [ pageLoading, setPageLoading] = useState(false)
    const [ apiError, setApiError] = useState('')
    const [ total, setTotal] = useState(0)

    useEffect(() => {
        setCustomOptions()
        let path = props.location.pathname.split('/')
        if(path && path.length > 2){
            setPageLoading(true)
            setFromatID(path[path.length - 1])
            getDebateFormatInfo({ 'formatID' : path[path.length - 1]})
                .then(res => {
                    if(res && res.status){
                        if( typeof(res.data) === 'string'){
                            setApiError(res.data)
                        }
                        else {
                            setApiError(res.data.join(','))
                        }
                        setPageLoading(false)
                    }
                    else{
                        setCustomTimings(res)
                        setPageLoading(false)
                    }
                    
                })
        }
       calculateSum()
    }, [])

    const calculateSum = () => {
        let sum = 0
        Object.values(customTimings).forEach(element => {
            if( !isNaN(parseInt(element)))
                sum = sum + parseInt(element)
        });
        setTotal(sum)
    }

    const setCustomOptions = () => {
		let values = []
		for(let i = 0; i < 61; i++) {
			values.push(i)
		}
		setvalue(values)
    } 

    const submit = () => {
        setLoading(true)
        let timings = {
            "debate_format_type":"CUSTOM"
        }

        if(formatID !== ''){
            updateDebateFormat({'debate_format_id' : formatID ,
                ...props.debateFormat, ...customTimings, ...timings })
                .then(res => {
                    if(res && res.status){
                        if( typeof(res.data) === 'string'){
                            setApiError(res.data)
                        }
                        else {
                            setApiError(res.data.join(','))
                        }
                        setLoading(false)
                    }
                    else {
                        let debateInfo = JSON.parse(localStorage.getItem('debate-info'))
                        localStorage.setItem('debate-info', JSON.stringify({ ...debateInfo, 'debate_format_id' : res.customFomratID }) )
                        //setLoading(false)
                        // toast.success('Debate information updated successfully')

                        updateDebateInfo({ ...debateInfo, 'debate_format_id' : res.customFomratID })
                       
                    }
                })
        }
        else {
            setDebateFormat({ ...customTimings, ...timings })
            .then(res => {
                if(res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                    }
                    else {
                        setApiError(res.data.join(','))
                    }
                    setLoading(false)
                }
                else {
                    let debateInfo = JSON.parse(localStorage.getItem('debate-info'))
                    localStorage.setItem('debate-info', JSON.stringify({ ...debateInfo, 'debate_format_id' : res.customFomratID }) )
                    
                    setLoading(false)
                    props.history.push('/invite_users')
                }
            })
        }
    }


    const updateDebateInfo = (info) => {
        updateDebate({ ...info, 'debateID': info.id, 'debate_start_datetime' : moment(info['debate_start_datetime']).format('YYYY-MM-DD') })
                .then(res => {
                    if(res && res.status){
                        if( typeof(res.data) === 'string'){
                            setApiError(res.data)
                        }
                        else {
                            setApiError(res.data.join(','))
                        }
                        setLoading(false)
                    }
                    else {
                        setLoading(false)
                       	//localStorage.removeItem('debate-info')
                        //setApiSuccess('Debate schedule updated successfully.')
                        
                        props.history.push(`/invite_users/${info.id}`)     
                    }
                })
    }
    
    const back = () => {
        let debateInfo = JSON.parse(localStorage.getItem('debate-info'))
        props.history.push(`/edit_debate/${debateInfo.id}`)
    }
    

    const setTimings = (data) => {
        setCustomTimings(data)
        calculateSum()
    }
    return(
        <section className="join-debate-world profile-main">
			<div className="container">
                <PageLoader show={pageLoading} message={'Loading....'}>
				<div className="row padding-l-r pattern-bg">
					<div className="col-12 col-lg-9 mx-auto d-flex align-items-center justify-content-center" style={{ position: 'relative', zIndex: 0 }}>
						<div className="profile_section w-100">
                        {apiError !== ''? <p className="notification alert">{apiError}</p>: null}
							<div className="profile-inner custom-width-table">
								<h2 className="text-center">Design your debate</h2>
                                    <form>
                                        <div className="scroll_box">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Speech</th>
                                                        <th scope="col" width="120">Time Limit<br />(60 mins or less)</th>
                                                        <th scope="col">Responsibility of the Debater</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row"><input type="text" value="Affirmative Constructive" disabled/></th>
                                                        <td>    
                                                            <select value={customTimings["debate_format_constructive_A"]} 
                                                                name="one" id="one" onChange={(e) => setTimings({...customTimings, "debate_format_constructive_A" : parseInt(e.target.value) }) }>
                                                                <option value={parseInt(0)}>Choose time</option> 
                                                                {values.map(data => <option value={data}>{data} min</option>)}
                                                            </select>   
                                                        </td>
                                                        <td><input type="text" disabled value="Present the affirmative case" /></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <input type="text" value="Negative Cross-Examination" disabled/>
                                                        </th>
                                                        <td>
                                                            <select value={customTimings["debate_format_constructive_B"]} name="one" id="one" onChange={(e) => setTimings({...customTimings, "debate_format_constructive_B" : parseInt(e.target.value) }) }>
                                                                <option value={parseInt(0)}>Choose time</option> 
                                                                {values.map(data => <option value={data}>{data} min</option>)}
                                                            </select>
                                                        </td>
                                                        <td><input type="text" value="Negative asks questions of the affirmative" disabled/></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row"><input type="text" value="Negative Constructive/Negative Rebuttal" disabled/></th>
                                                        <td>
                                                            <select value={customTimings["debate_format_rebuttal_B"]} name="one" id="one" onChange={(e) => setTimings({...customTimings, "debate_format_rebuttal_B" : parseInt(e.target.value) }) }>
                                                                <option value={parseInt(0)}>Choose time</option> 
                                                                {values.map(data => <option value={data}>{data} min</option>)}
                                                            </select>
                                                        </td>
                                                        <td><input type="text" value="Present the negative case and refute the affirmative case" disabled/></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row"><input type="text" value="Affirmative Cross-Examination" disabled/></th>
                                                        <td>
                                                            <select value={customTimings["debate_format_crossfire1_AB"]}name="one" id="one" onChange={(e) => setTimings({...customTimings, "debate_format_crossfire1_AB" : parseInt(e.target.value) }) }>
                                                                <option value={parseInt(0)}>Choose time</option> 
                                                                {values.map(data => <option value={data}>{data} min</option>)}
                                                            </select>
                                                        </td>
                                                        <td><input type="text" value="Affirmative asks questions of the negative" disabled/></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row"><input type="text" value="First Affirmative Rebuttal" disabled/></th>
                                                        <td>
                                                            <select value={customTimings["debate_format_rebuttal_A"]} name="one" id="one" onChange={(e) => setTimings({...customTimings, "debate_format_rebuttal_A" : parseInt(e.target.value) }) }>
                                                                <option value={parseInt(0)}>Choose time</option> 
                                                                {values.map(data => <option value={data}>{data} min</option>)}
                                                            </select>
                                                        </td>
                                                        <td><input type="text" value="Refute the negative case and rebuild the affirmative case" disabled/></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row"><input type="text" value="Total duration" disabled/></th>
                                                        <td>{total}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>	

                                        <div className="col-6 mx-auto">		
                                            <div className="col-12">
                                                <button className="btn" type="button" onClick={submit}>
                                                {loading ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30} /> : 'Submit'}</button>
                                            </div>		
                                            <div className="col-sm-12">
                                                <a className="back-btn" onClick={back}>Back</a>
                                            </div>
                                        </div>
                                    </form>
							    </div>
						    </div>
					    </div>
				    </div>
                    </PageLoader>
			    </div>
		    </section>
    )
}


export default CustomDebateTable;