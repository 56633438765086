import React, { useState } from 'react';
import PageLoader from 'react-loader-advanced'

function FirstPage (props) {
	const [ loading, setLoading] = useState(false)

	const setRole = (role) => {
		localStorage.setItem('role', role)
		setLoading(true)
		setTimeout(() => props.history.push('/signup'), 2000)
		setLoading(false)
	}

	
    return(
		<PageLoader show={loading} message={'Updating...'}>
			<section className="debate-world">
				<div className="container">
					<div className="row padding-l-r">
						<div className="col-12 col-lg-7">
							<div className="debate-img">
								<img src="images/debate-vector-1.png" alt="debate-vector" />
								<p>We live in a world that is hyper-connected through technology and social media. Yet, the global community is more divided than ever based on ideological positions, self-interest, and <br />absence of genuine dialogs. </p>
								<p>DebateNow seeks to provide a technology-driven, neutral, engaging, and safe platform for <br />individuals and communities around the world to debate on topics that matter to them.</p>
							</div>
						</div>
						
						<div className="col-12 col-lg-5 d-flex align-items-center justify-content-end">
							<div className="debate-world-content custom-width-content">
								<h1>Debate <br /><span>with the world</span></h1>
								<ul className="d-flex align-items-center justify-content-center">
									<li onClick={() => setRole('DEBATER')}>
										<a className="nav-link custom-btn" href="#">Debater</a></li>
									<li onClick={() => setRole('PARENT')}>
										<a className="nav-link custom-btn" href="#">Parent</a></li>
									<li onClick={() => setRole('TEACHER')}>
										<a className="nav-link custom-btn" href="#">Teacher</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</PageLoader>
    )
}

export default FirstPage;