import React, { useState, useEffect } from "react";
import { isLoggedIn } from '../utils/api'
import { toast } from 'react-toastify'
import { checkUserEmail, skipEditProfileAPI } from "../api/auth";
import { sendInvite } from "../api/common";
import PageLoader from 'react-loader-advanced'
import Loader from 'react-loader-spinner'

function InviteStudent(props) {
    const [invitations, setInvitationList ] = useState([])
    const [teacher, setTeacher] = useState({})
    const [loading, setLoading ] = useState([])
    const [email, setEmail] = useState({})
    const [errorIndex, setErrorIndex] = useState([])
    const [disabled, setDisabled] = useState([])
    const [userInfo, setUserInfo] = useState({})
    const [apiError, setApiError] = useState('')
    const [apiSuccess, setApiSuccess] = useState('')
    const [ fetching, setFetching] = useState(false)
  
    const sendEmailInvite = (data, index) => {
       // setLoading({ [index] : true })
       console.log(data,index,  "email")
        setFetching(true)
        //setDisabled({ ...disabled, [index] : true })
        //console.log(invitations, index, invitations[index] , {...invitations[index], ...teacher}, "test")
        let res = validateEmail(data.email)
        if(res){
            return new Promise ((resolve, reject) => 
                checkUserEmail({'email' : data.email })  
                    .then(res => {
                        if(res && res.status){
                            sendInvite({ ...data, ...teacher})
                                .then(res => {
                                    if(res && res.status){
                                        if( typeof(res.data) === 'string'){
                                            setApiError(res.data)
                                            //setLoading({ ...loading, [index] : false })
                                            setFetching(false)
                                            reject(res)
                                        }
                                        else {
                                            setApiError(res.data.join(','))
                                            //setLoading({ ...loading, [index] : false })
                                            setFetching(false)
                                            reject(res)
                                        }
                                    }
                                    else {
                                        resolve(res)
                                        //setApiSuccess('Invitation sent successfully!')
                                        //setLoading({ ...loading, [index] : false })
                                        //setDisabled({ ...disabled, [index] : true })
                                        setFetching(false)
                                    }
                                })
                    }
                    else {
                        setApiError('User already exist in our system.')
                        reject(res)
                        //setLoading({ ...loading, [index] : false })
                    }
                })) 
        }
        else {
            setErrorIndex({ ...errorIndex, [index] : true })
            setFetching(false)
        }
    }

    const addMore = () => {
        let arr = [...invitations]
        arr.push({ "email" : '' })
        setInvitationList(arr)
        setApiSuccess('')
        setApiError('')
    }

    useEffect(() => {
        let res = isLoggedIn() ||  {}
		if(typeof(res) === 'string'){
			res = JSON.parse(res) || {};
            if(res.sso_id){
                setTeacher({ 'teacherID': res.sso_id })
                setUserInfo(res)
            }
        } else {
            setApiError('Please login before inviting student/s')
        }

        if(invitations.length === 0 && invitations.length < 10){
            addMore()
        }
    }, [])

    const validateEmail= (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		    return re.test(String(email).toLowerCase());
    }
    
    const onChangeEmail = (e, index) => {
        //console.log( e.target.value, "test value")
        let invitationArray = [...invitations]
        invitationArray[index] = { 'email' : e.target.value }
        //console.log( invitationArray, "array")
        setInvitationList([...invitationArray])
        //setErrorIndex('')
    }

    const skip = () => {
        props.history.push('/thanks_teacher')
    }

    const validate = (validationArray) => {
        setLoading(true)
        let verify = validationArray.map(Element => {
            let arr = Object.values(Element)
            let filtered = arr.filter(item => item) || []
            if(filtered.length > 0){
                return Element
            }
            else {
                return false
            }
        })
        console.log(verify, verify.includes(false), "verify")
        if(verify.includes(false)){
            setLoading(false)
            console.log('verified false')
           return setApiError('Please fill required info.') 
        }
        else {
            return true
        }
    }

    const invite = () => {
        let result = validate(invitations) 
        if(result){
            let promiseArray = invitations.map((data, index) => sendEmailInvite(data, index))
            Promise.all(promiseArray)
                .then(res => {
                    if( res && (res.length === invitations.length)){
                        setApiSuccess('Invitation/s sent successfully!')
                        setLoading(false)
                        setFetching(false)
                        setTimeout(() => {
                            props.history.push('/thanks_teacher')
                        }, 2000) 

                    }
                })
        }
       
    }

    return(
        <PageLoader show={fetching} message={'Sending Invitations.....'}>
        <section className="join-debate-world profile-main">
			<div className="container">
				<div className="row padding-l-r pattern-bg">
					<div className="col-12 col-lg-10 mx-auto d-flex align-items-center justify-content-center" style={{ position: 'relative', zIndex: 0 }}>
						<div className="profile_section w-100">
                        {apiSuccess !== ''? <p className="notification success">{apiSuccess}</p>: null}
                        {apiError !== ''? <p className="notification alert">{apiError}</p>: null}
							<div className="profile-inner">
								<h2 className="text-center">Invite Student/s</h2>
                                <form>
                                    {invitations && (invitations.length > 0) ? 
                                        invitations.map((invite, index) => <div className="form-group row invite-student" key={index}>
                                            <label for="username" className="col-sm-4 col-form-label text-right">Student {index + 1}</label>
                                            <div className="col-sm-8">
                                                <input disabled={disabled[index]} onChange={(e) => onChangeEmail(e, index)}  placeholder="Email"/>
                                                {errorIndex[index] ? <p className="alert">Please enter valid email</p> : null}
                                            </div>
                                        </div>) : null}
                                    <div className="col-12">										
                                        <div className="row">
                                            <div className="col-sm-4"></div>
                                            <div className="col-sm-8">
                                                <div className="row">
                                                    <div className="col-6 mx-auto">  
                                                        <button className="btn add-more" type="button" onClick={addMore}>+ Add More</button>
                                                        <button onClick={invite}
                                                            className="btn" type="button">
                                                            {fetching ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30} /> : 'Invite' }
                                                        </button>
                                                        <button 
                                                            onClick={skip}
                                                            className="btn" type="button">Skip
                                                        </button>
                                                        {/* <button className="btn" type="button">Submit</button> */}
                                                    </div>
                                                </div>
                                            </div>												
                                        </div>									
                                        <div className="row">
                                            <div className="col-sm-4"></div>
                                            <div className="col-sm-8">
                                                <a className="back-btn" onClick={() => props.history.goBack()}>back</a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </PageLoader>
    )
}

	

export default InviteStudent



