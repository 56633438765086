import * as types from '../action_types/debate'


let initialState = {
    debateList : [], 
    topicList : [], 
    singleDebate: {}, 
    searchText: '', 
    debateInfo: {}, 
    debateFormat: {}
}

const debate = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DEBATES:
            return {...state, debateList : action.payload }
        case types.GET_TOPICS:
            return {...state, topicList : action.payload }
        case types.GET_SINGLE_DEBATE: 
            return {...state, singleDebate : action.payload }
        case types.SAVE_SEARCH_TEXT: 
            return {...state, searchText: action.payload }
        case types.SAVE_DEBATE_INFO:
            return {...state, debateInfo: action.payload }
        case types.GET_DEBATE_FORMAT:
            return {...state, debateFormat: action.payload }
        default:
            return state
    }
}

export default debate