import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { changePasswordAPI } from '../../api/auth';


const ChangePassword = (props) => {
    const [ passwordInfo, setInfo] = useState({})
    const [ errors, setErrors] = useState({})


    const validate = (data) => {
        if(data.password === data.confirmed){
            setErrors({})
            return data
        }
        else {
            let err = {
                'password' : 'New and Confirmed password should be same.', 
                'confirmed': 'New and Confirmed password should be same.'
            }
            setErrors(err)
            return {}
        }
    }

    const change = () => {
        let validInfo = validate(passwordInfo)
        console.log(validInfo, "test")
        if(Object.keys(validInfo).length === 3){
            changePasswordAPI({...passwordInfo, 'sso_id' : props.id })
                .then(res => {
                    if( res && res.status){
                        if( typeof(res.data) === 'string'){
                            props.setApiError(res.data)
                        }
                        else {
                            props.setApiError(res.data.join(','))
                        }
                    }
                    else {
                        props.setApiError('')
                        //console.log('Password Updated Successfully.')
                        props.setSuccessMessage('Password Updated Successfully.')
                        props.next()
                    }
                })
        }
        else {
            console.log(errors)
        }
    }

    return (
        <div className="profile-inner">
            <div className="scroll_box">
                <div className="row">
                <div className="col-sm-12">
                    <h2 className="text-center update-password-head mt-3">Update Password</h2>
                    <form>
                    <div className="form-group row" style={{ marginTop : 5}}> 
                        <label for="bio" className="col-sm-4 col-form-label text-right">Current Password<sup>*</sup></label>
                        <div className="col-sm-8">
                            <input onChange={(e) => setInfo({ ...passwordInfo, 'old_password': e.target.value })}  
                                colSpan="6" type="text" 
                                value={ passwordInfo.old_password || ''} 
                                type="password"
                                className="form-control" id="bio" placeholder="Current Password"></input>
                        </div>         
                    </div>
                    <div className="form-group row">
                        <label for="bio" className="col-sm-4 col-form-label text-right">New Password<sup>*</sup></label>
                        <div className="col-sm-8">
                            <input onChange={(e) => setInfo({ ...passwordInfo, 'password': e.target.value })}  
                                colSpan="6"
                                value={ passwordInfo.password || ''} 
                                type="password"
                                className="form-control" id="bio" placeholder="New Password"></input>
                            {errors['password'] ? <p className="alert">{errors['password']}</p> : null}
                        </div>         
                    </div>
                    <div className="form-group row">
                        <label for="bio" className="col-sm-4 col-form-label text-right">Re-type Password<sup>*</sup></label>
                        <div className="col-sm-8">
                            <input onChange={(e) => setInfo({ ...passwordInfo, 'confirmed': e.target.value })}  
                                colSpan="6" 
                                type="password"
                                value={ passwordInfo.confirmed || ''} 
                                className="form-control" id="bio" placeholder="Re-type password"></input>
                            {errors['confirmed'] ? <p className="alert">{errors['confirmed']}</p> : null}
                        </div>         
                    </div>
                    <div className="row">
                        <div className="col-sm-8 col-xs-12 ml-auto">    
                            <button className="btn" type="button" onClick={change}>Submit</button>
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
        </div>)
}

export default ChangePassword