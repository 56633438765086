import { apiPost, apiGet } from '../utils/api'
import { DEBATE_API_URL, AUTH_API_URL } from '../constants'
import { data } from 'jquery'


export const scheduleDebate = (data) => {
    return apiPost(DEBATE_API_URL + `debate/create`, data,  false)
        .then(res => res)
        .catch(err => err.response)
       
}

export const getDebateFormats = () => {
    return apiGet(DEBATE_API_URL + `debate/listingDebatFormat`, false)
        .then(res => res)
        .catch(err => err.response)
}

export const getSavedDebatesList = (data) => {
    return apiPost(DEBATE_API_URL + `debate/listingDebates`, data )
        .then(res => res)
        .catch(err => err.response)
}

export const getDebaterInfo = (data) => {
    return apiPost(AUTH_API_URL + `auth/debaterInfo`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const setDebateFormat = (data) => {
    return apiPost(DEBATE_API_URL + `debate/customDebateFormat`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const searchDebates = (data) => {
    return apiPost(DEBATE_API_URL + `debate/search`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const getTrendingTopics = () => {
    return apiGet(DEBATE_API_URL + `debate/listingTrendingTopics`)
        .then(res => res)
        .catch(err => err)
} 

export const getDebateInfo = (data) => {
    return apiPost(DEBATE_API_URL + `debate/debateInfo`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const acceptDebate = (data) => {
    return apiPost(DEBATE_API_URL + `debate/acceptDebate`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const declineDebate = (data) => {
    return apiPost(DEBATE_API_URL + `debate/declineDebate`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const getDebateFormatInfo = (data) => {
    return apiPost(DEBATE_API_URL + `debate/customFormatInfo`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const updateDebate = (data) => {
    return apiPost(DEBATE_API_URL + `debate/update`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const updateDebateFormat = (data) => {
    return apiPost(DEBATE_API_URL + `debate/updateCustomDebateFormat`, data)
        .then(res => res)
        .catch(err => err.response)
}


export const fetchOpenCloseDebates = (data) => {
    return apiPost(DEBATE_API_URL + `debate/listingOpenCloseDebates`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const fetchUpcomingDebates = (data) => {
    return apiPost(DEBATE_API_URL + `debate/acceptedDebates`, data)
        .then(res => res)
        .catch(err => err.response)
}



