import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { GoogleLogin }from 'react-google-login'
import FacebookLogin from 'react-facebook-login'
import { socialLogin } from '../api/auth'
import PageLoader from 'react-loader-advanced';

function LandingPage (props) {
    const [info, setInfo] = useState({ 'role' : localStorage.getItem('role') || 'DEBATER' })
	const [dob, setDob] = useState('')
	const [enable, setEnable] = useState(true)
	const [apiError, setApiError] = useState("")
	const [message, setMessage] = useState("") 
	// const [yearValues, setYears] = useState([])
	const [ day, setDayvalue] = useState('')
	const [ month, setMonth] = useState('')
	const [ year, setyearvalue] = useState('')
	const [age, setAge] = useState(0)
	const [pageLoading, setPageLoading] = useState(false)
	
	// const valid = ( current ) => {
	// 	let yesterday = moment().subtract( 1, 'day' )
	// 	return current.isBefore(yesterday);
	// };
	

	useEffect(() => {
		let dob = `${year}-${month}-${day}`
		//console.log( `{${year}-${month}-${day}}`, moment(`${year}-${month}-${day}`).format('YYYY-MM-DD') , "test")
		let date = moment(dob).format('YYYY-MM-DD')
		let years = moment().diff(date , 'years',false);
		setDob(dob)
		setAge(years)
		localStorage.setItem('age', years)
		setMessage('')
		
		localStorage.setItem('info', JSON.stringify({ 'role': info.role, 'dob': `${year}-${month}-${day}` }))
	}, [ info, day, month, year, dob ])


	const checkDob = () => {
		setPageLoading(true)
		if( dob === '--'){
			setMessage('Please provide Date of birth for continuing signup')
			setPageLoading(false)
		} else {
			if((moment(dob, 'YYYY-M-D', true).isValid())){
				return props.history.push('/signup_step2')
			}
			else {
				if(!(moment(dob, 'YYYY-MM-DD', true).isValid())){
					setMessage('Please provide valid date as Date of Birth')
				}
				else {
					props.history.push('/signup_step2')
				}
			}
			setPageLoading(false)
		}
		
	}

	const checkDobIfSocial = () => {
		setPageLoading(true)
		if( dob === '--'){
			setMessage('Please provide Date of birth for continuing signup')
			setPageLoading(false)
		} else {
			if((moment(dob, 'YYYY-M-D', true).isValid())){
				console.log( "reached 1")
				setEnable(false)
			}
			else {
				if((moment(dob, 'YYYY-MM-DD', true).isValid())){
					console.log( "reached 2")
					setEnable(false)
				}
				setMessage('Please provide valid date as Date of Birth')
			}
			setPageLoading(false)
		}
	}

	const renderImage = () => {
		switch(info.role){
			case 'PARENT':
				return  <img src="images/child.jpg" alt="debate-upcomming" />
			case 'TEACHER':
				return <img src="images/parent.jpg" alt="debate-upcomming" />
			default:
				return <img src="images/debate-vector-2.png" alt="debate-upcomming" />
		}
	}

	const responseGoogle = (e) => {
		//console.log(e, "got response")
		setPageLoading(true)
		let userInfo = e.profileObj || {}
		let data = {
			"dob": moment(dob).format('YYYY-MM-DD') ,
			"email": userInfo.email,
			"debater_first_name": userInfo.givenName,   
			"debater_last_name": userInfo.familyName,
			"account_type": "GOOGLE", 
			"role": info.role
		}

		socialLogin(data)
		.then (res =>  {
		    if(res && res.status){
				if( typeof(res.data) === 'string'){
					setApiError(res.data)
				}
				else {
					setApiError(res.data.join(','))
				}
				setPageLoading(false)
			}
			else {	
				setPageLoading(false)
				localStorage.setItem('user', JSON.stringify(res))
				setTimeout(() => { 
					if(res.sso_role === 'PARENT'){
						window.location = '/add_child'
					}
					else if(res.sso_role === 'TEACHER'){
						window.location = '/invite_student'
					}
					else if(res.sso_role === 'DEBATER')
						window.location = '/profile' 
				}, 3000);
			}
		})
	}

	const responseFacebook = (e) => {
		setPageLoading(true)
		let data = {
			"dob": moment(dob).format('YYYY-MM-DD'),
			"email": e.email || '',
			"debater_first_name": e.first_name,   
			"debater_last_name": e.last_name,
			"account_type": "FACEBOOK", 
			"role": info.role
		}

		socialLogin(data)
            .then (res =>  {
                if(res && res.status){
					if( typeof(res.data) === 'string'){
						setApiError(res.data)
					}
					else {
						setApiError(res.data.join(','))
					}
					setPageLoading(false)
				}
				else{	
					setPageLoading(false)
					localStorage.setItem('user', JSON.stringify(res))
					setTimeout(() => { 
						if(res.sso_role === 'PARENT'){
							window.location = '/add_child'
						}
						else if(res.sso_role === 'TEACHER'){
							window.location = '/invite_student'
						}
						else if(res.sso_role === 'DEBATER')
							window.location = '/profile'
					 }, 3000);
				}
			})
		}
		
	const setDate = (key, value) => {
		checkDobIfSocial()
		switch(key){
			case 'day':
				setDayvalue(value)
				break;
			case 'month':
				setMonth(value)
				break;
			case 'year': 
				setyearvalue(value)
				break;
		}
	}

	let days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
	let months = [
		{ 'label': 'Jan', 'value': 1 },
		{ 'label': 'Feb', 'value': 2 }, 
		{ 'label': 'Mar', 'value': 3 }, 
		{ 'label': 'Apr', 'value': 4 }, 
		{ 'label': 'May', 'value': 5 }, 
		{ 'label': 'Jun', 'value': 6 }, 
		{ 'label': 'Jul', 'value': 7 }, 
		{ 'label': 'Aug', 'value': 8 }, 
		{ 'label': 'Sep', 'value': 9 }, 
		{ 'label': 'Oct', 'value': 10 }, 
		{ 'label': 'Nov', 'value': 11 }, 
		{ 'label': 'Dec', 'value': 12 }
	]
	let yearValues = [1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019]

    return(
		<PageLoader show={pageLoading} message={'Loading....'}>
        <section className="join-debate-world">
			<div className="container">
				<div className="row padding-l-r">
					<div className="col-12 col-lg-7">
						<div className="join-debate-img">
							<h2>Debate with anyone, anywhere. <i>For free</i>.</h2>
							<p>Build your debating skills by engaging in structured and customizable debate formats.</p>
							<img src="images/debate-vector-2.png" alt="debate-upcomming" />
						</div>
					</div>
					
					<div className="col-12 col-lg-5 d-flex align-items-center justify-content-center">
						<div className="join-debate-content join-spacing">
						{apiError !== ''? <p className="notification alert">{apiError}</p>: null}
							<h5>Join DebateNow as a </h5>
							<ul className="divide align-items-center">
								<li onClick={() => setInfo({ ...info, 'role':'DEBATER'})}>
									<a href="#" className={info.role === 'DEBATER' ? "nav-link custom-btn active" : "nav-link custom-btn"}>Debater</a></li>
								<li onClick={() => setInfo({ ...info, 'role':'PARENT'})}>
									<a href="#" className={info.role === 'PARENT' ? "nav-link custom-btn active" : "nav-link custom-btn"}>Parent</a></li>
								<li onClick={() => setInfo({ ...info, 'role':'TEACHER'})}>
									<a href="#" className={info.role === 'TEACHER' ? "nav-link custom-btn active" : "nav-link custom-btn"}>Teacher</a></li>
							</ul>
							<div className="join-dob spacing">
							<h5>What is your date of birth?</h5>
								
								<form className="align-items-center divide">
									<select  id ="month" name="mm" value={month} onChange={(e) => setDate('month',e.target.value)}>
										<option key={0}  value={''}>Month</option>
										{months.map(data => <option key={data.value}  value={data.value}>{data.label}</option>)}
									</select>

									<select id ="day" name="dd" value={day} onChange={(e) => setDate('day', e.target.value)}>
										<option key={0}  value={''}>Day</option>
										{days.map(day => <option key={day}  value={day}>{day}</option>)}
									</select>
									
									<select id ="year" name ="yyyy" value={year} onChange={(e) => setDate('year', e.target.value)}>
										<option key={0}  value={''}>Year</option>
										{yearValues.map(day => <option key={day} value={day}>{day}</option>)}
									</select>
							</form>
							</div>	
							{message !== '' ? <p className="alert">{message}</p>: null}
							 <div className="debate-social">
								<ul>
									<li>
										<GoogleLogin
											clientId={process.env.REACT_APP_GOOGLE_CLIENT}
											buttonText="Continue with Google"
											onSuccess={responseGoogle}
											cookiePolicy={'single_host_origin'}
											isSignedIn={false}
											autoLoad={false}
											disabled={enable}	
										/>
									</li>
									<li>
										<FacebookLogin
											appId={process.env.REACT_APP_FACEBOOK_ID}
											autoLoad={false}
											fields="first_name,last_name,email,birthday"
											scope="public_profile"	
											callback={responseFacebook} 
											icon="fa-facebook"
											isDisabled={enable}
										/>
									</li>
								<li> 
									<a onClick={checkDob} href={'#'}>
									<span>
										<img src="images/email.svg" alt="Email"/>
									</span>
								Continue with Email</a></li> 
								</ul>
							</div> 
							
						</div>
					</div>
				</div>
			</div>
		</section>
		</PageLoader>
    )
}

export default LandingPage