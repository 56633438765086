import React from 'react'


function Footer () {
    return (
        <footer >
			<div className="help-id">
				<p>help@debatenow.org</p>
			</div>
		</footer>
    )
}

export default Footer