import React from 'react'


function LearnMore() {
    return(
        <section className="join-debate-world profile-main">
			<div className="container">
				<div className="row padding-l-r pattern-bg">
					<div className="col-12 col-lg-9 mx-auto d-flex align-items-center justify-content-center" style={{ position: 'relative', zIndex: 0 }}>
						<div className="profile_section w-100 d-flex align-items-center">
							<div className="profile-inner notice">
								<h5>If you want to learn more before adding a child <a href="/faq">click here</a></h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}

export default LearnMore