import React, { useEffect, useState } from 'react'
import { getTopicList } from '../actions/debate'
import { getDebaterInfo } from '../api/debate'
import moment from 'moment'
import { connect } from 'react-redux';
import PageLoader from 'react-loader-advanced'

function TrendingTopics (props) {
    const [ age, setAge] = useState(20)
    const [ fetching, setFetching] = useState(false)
    const [ redirect, setRedirect] = useState(false)
    const [ apiError, setApiError] = useState('')

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user')) || {}
		if( user && user.sso_id){
            setFetching(true)
            calculateAge(user)	
            getInfo(user.sso_id)
        }
        
        if( props.topicList && props.topicList.length === 0){
            props.getTopics()
        }
        setFetching(false)
    }, [props.topicList])

    const calculateAge = (user) => {
		let date = moment(user.sso_dob).format('YYYY-MM-DD')
		let years = moment().diff(date , 'years',false);
		setAge(years)
    }

    const titleCase = (str) => {
		str = str.toLowerCase().split(' ');
		for (var i = 0; i < str.length; i++) {
		  str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
		}
		return str.join(' ');
    }  
    
    const getInfo = (id) => {
        getDebaterInfo({ 'sso_id': id })
            .then(res => {
                if(res && res.status){
                    return 0 
                }
                else {
                    //console.log(res, ((res.debater_bio === '') && (res.debater_zoom_link !== '')), "response" )
                    if((res.debater_bio === '') && (res.debater_zoom_link === '') ) {
                        setRedirect(true) 
                    }
                }
            })
    }

    const goToDebatePage = (id) => {
        if(redirect){
            setApiError('Please add your bio and zoom link to schedule the debate, thus redirecting to profile')
            setTimeout(() => { props.history.push('/profile') }, 5000)
        }
        else {
            props.history.push(`/create_debate/${id}`)
        }
    }
    
    return (
        <PageLoader show={fetching} message={'Loading...'}>
        <section className="join-debate-world">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-5">
                        <div className="join-debate-img">
                            {(age > 13) ? <img src="images/debate-vector-3.png" alt="debate-upcomming" /> : <img src="images/debate-vector-5.png" alt="debate-upcomming" />}
                        </div>
                    </div>
            
                    <div className="col-12 col-lg-7 d-flex justify-content-center">
                        <div className="join-debate-content upcoming_box">
                            {apiError !== ''? <p className="notification alert">{apiError}</p>: null}
                            <h1>Trending Topics</h1>
                                <div className="topic debate-topic">
                                 {props.topicList && (props.topicList.length > 0) ? 
                                    props.topicList.map(data => {
                                        return (<div className="box" key={data.id} onClick={() => goToDebatePage(data.debate_info_id)}>
                                            <h6>{titleCase(data.topic)}</h6>		
                                        </div>) })
                                        : <p>No topics available.</p>}
                                </div>
                            <a className="back-btn" onClick={() => props.history.goBack()}>back</a>
                        </div>                        
                    </div>
                </div>
            </div>
        </section> 
    </PageLoader>)
}

const mapStateToProps = state => {
	return {
		topicList: state.debateReducer.topicList 
	}
}

const mapDispatchToProps = dispatch => {
    return {
		getTopics: () => dispatch(getTopicList())
	}
}




export default connect(mapStateToProps, mapDispatchToProps)(TrendingTopics);