import React, { useState, useEffect } from 'react'


function PublicAndClosed (props) {
    let [ partnerEmail, setEmail ] = useState(props.info.partner_emails[0] || '')
    let [ opponentEmails, setOpponentEmail ] = useState(props.opponent_emails || {})

    useEffect(() => {
        let mails = []
        mails.push(partnerEmail)
        props.updateInfo({ ...props.info,   
            "partner_emails": [...mails], 
            "opponent_emails": Object.values(opponentEmails) 
        })
    },[partnerEmail, opponentEmails])

    return (
        <div className="add-fields col-lg-10 col-sm-10 col-xs-12 ml-auto">
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <input value={partnerEmail} onChange={(e) => setEmail(e.target.value)}  type='email'
                            className="form-control" placeholder="Add partner email*"/>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <input  value={opponentEmails['mail1']} type='email' onChange={(e) => setOpponentEmail({ ...opponentEmails, 'mail1' : e.target.value })}
                            className="form-control" placeholder="Add opponent email*"/>
                    </div>
                    <div className="form-group">
                        <input value={opponentEmails['mail2']} type='email'  onChange={(e) => setOpponentEmail({ ...opponentEmails, 'mail2' : e.target.value })}
                            className="form-control" placeholder="Add opponent email*"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PublicAndClosed