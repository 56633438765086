import React, { useState, useEffect } from 'react'
import { createBrowserHistory } from 'history';
import { isLoggedIn , logOut } from '../../utils/api'
import LoginModal from '../Modal/LoginModal';
import { getDebaterInfo, searchDebates } from '../../api/debate';
import { toast } from 'react-toastify';
import { GoogleLogout } from 'react-google-login'
import { saveSearchDebateList, saveSearchText } from '../../actions/debate'
import { connect } from 'react-redux';
import Notification from '../common/Notification'
import logo from '../../images/logo.svg'
import dummy from '../../images/dummy.png'
import user from '../../images/user.svg'
import searchIcon from '../../images/search-icon.svg'

function Header(props) {
	const history = createBrowserHistory();
	const [ searchText, setSearchText ] = useState('')
	const [ loginModalOpen, setLoginModalOpen ] = useState(false);
	const [ isUserLoggedIn, setIsUserLoggedIn ] = useState(false);
	const [ show, toggle] = useState(false)
	const [ userInfo , setUserInfo] = useState({})

	const logout = () => {
		logOut()
		window.location = '/';
		setIsUserLoggedIn(false)
	}
	useEffect(() => {	
		let res = isLoggedIn() ||  {}
		if(typeof(res) === 'string'){
			res = JSON.parse(res) || {};
			if(res.sso_id){
				setIsUserLoggedIn(true);
				getDebaterInfo({ 'sso_id' : res.sso_id})
					.then(response => {
						if(response && response.status){
							if( typeof(response.data) === 'string'){
								toast.error(response.data)
							}
							else {
								toast.error(response.data.join(','))
							}
						}
						else {
							let name = (response !== undefined) && `${response.debater_first_name} ${response.debater_last_name}` 
							setUserInfo({ 'role' : res.sso_role, 'name' : name , 'id' : res.sso_id }) 
						}
					})
			}
		}
		setSearchText(props.text.s)
	}, [props.text])

	const gotoProfile = () => {
		window.location = '/edit_profile'
	}

	const openNav = () => {
		document.getElementById("mySidenav").style.width = "300px";
	 }
  
	const closeNav = () => {
		document.getElementById("mySidenav").style.width = "0";
	}

	const search = () => {
		window.location = `/search?s=${searchText}`
	}

	return (
	<>
		<div className="header-wrap">
			<div className="container">
				<header className="main-navigation">
					<nav className="navbar navbar-expand-lg navbar-light">
						<a className="navbar-brand" 
							href={ isUserLoggedIn ? "/upcoming_debates" : "/"}>
								<img src={logo} alt="logo" /></a>
						<span onClick={openNav}
							style={{ fontSize: '30px', 'cursor': 'pointer' }} className="menu-icon">&#9776;</span>
						<div className="collapse navbar-collapse" id="navbarSupportedContent">
							<div className="header_search">
								<form role="search" method="get" className="search-form form mx-auto desktop-view" action="">
									<label className="label-search">Search</label>
									<label>
										<span className="screen-reader-text">Search for...</span>
										<input
											onKeyPress={(e) => {
												if(e.key === 'Enter') {
													e.preventDefault()
													search()
												}
											} }
											onChange={(e) => setSearchText(e.target.value)}
											type="search" className="search-field" placeholder="by topic, format, language, motion" value={searchText || ''} name="s" title="" />
									</label>
									<button type="button" onClick={search} className="search-submit button"><img src={searchIcon} alt="search-icon" /></button>
								</form>
							</div>
							<ul className="navbar-nav ml-auto">
								<li className="nav-item active">
									<a className="nav-link" href="/faq">Faq <span className="sr-only">(current)</span></a>
								</li>

								{!isUserLoggedIn ?  <li className="nav-item" style={{  cursor : 'pointer' }}> 
										<a className="nav-link" onClick={() => setLoginModalOpen(true)}><img src={user} alt="Login" className="mr-2" />Log In</a>
									</li>
									: 
									<li className="nav-item">
										<div className="dropdown">
											<button onClick={ () => toggle(!show)} className="btn dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded={show}>
												<span><img src={dummy} width="40" alt="" onClick={ () => toggle(!show)}/></span> 
												<h4>{userInfo.name || 'LoggedIn User'} </h4>
											</button>
											<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
												<button className="dropdown-item" type="button" onClick={gotoProfile}>Profile</button>
												<button className="dropdown-item" type="button" onClick={() => {  window.location = "/create_debate" }}>Schedule Debate</button>
												{(userInfo.role !== undefined) && (userInfo.role === 'PARENT') ? <button className="dropdown-item" type="button" onClick={() => {  window.location = "/add_child" }}>Invite Child</button> : null}
												{(userInfo.role !== undefined) && (userInfo.role === 'TEACHER') ?<button className="dropdown-item" type="button" onClick={() => {  window.location = "/invite_student" }}>Invite Student</button> : null}
												<button className="dropdown-item" type="button" onClick={() => {  window.location = "/trending" }}>Trending Topics</button>
												<button className="dropdown-item" type="button" onClick={() => {  window.location = "/upcoming_debates" }}>Upcoming Debates</button>
												<button className="dropdown-item" type="button" onClick={logout}>Logout</button>
											</div>
										</div>
									</li>}
		
									{false ? 
										<GoogleLogout  
											clientId="679470834123-ogbb9psnebaf1c335s0gpm5bcqd9tub5.apps.googleusercontent.com"
											onLogoutSuccess={e => console.log( e, "event")}/> 
										: null }
									{!isUserLoggedIn  ? <li className="nav-item">
										<a className="nav-link custom-btn" href="/signup">Sign Up</a>
								</li> : null}
							</ul>
						</div>
					</nav>
				</header>
				
			
				<div id="mySidenav" className="sidenav">
				<a href="/" className="closebtn" onClick={closeNav}>&times;</a>
				{!isUserLoggedIn ?  <ul className="navbar-nav">	
					<li className="nav-item active">
						<a className="nav-link" href="/">Faq <span className="sr-only">(current)</span></a>
					</li>
					<li className="nav-item">
						<a className="nav-link" onClick={() => {
							setLoginModalOpen(true)
							}}>Log In</a>
					</li>
					<li className="nav-item">
						<a className="nav-link custom-btn" href="/signup">Sign Up</a>
					</li>
					</ul>: <ul className="navbar-nav"> 
							<li className="nav-item">
								<a className="nav-link" href="/profile">Profile</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/create_Debate">Schedule Debate</a>
							</li>

							<li className="nav-item">
								<a className="nav-link" href="/trending">Trending Topics</a>
							</li>

							<li className="nav-item">
								<a className="nav-link" href="/upcoming_debates">Upcoming Debates</a>
							</li>

							{(userInfo.role !== undefined) && (userInfo.role === 'PARENT') ? 
								<li className="nav-item">
									<a className="nav-link" href="/add_child">Invite Child</a>
								</li> : null}
							
							{(userInfo.role !== undefined) && (userInfo.role === 'TEACHER') ?
								<li className="nav-item">
									<a className="nav-link" href="/invite_student">Invite Student</a>
								</li> : null}
						</ul>}
					<Notification/>
				</div>  
			</div>
			<LoginModal 
				history={history}
				open={loginModalOpen} 
				onClose={() => setLoginModalOpen(false)}/>
		</div>
	</>)
}



const mapStateToProps = (state) => {
	return {
		text : state.debateReducer.searchText 	
	}
}

export default connect(mapStateToProps)(Header);

