import * as types from '../action_types/debate'
import { 
    searchDebates, 
    getTrendingTopics,  
    getDebateInfo, 
    getDebateFormatInfo 
} from '../api/debate' 


export const getDebatesById = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        getDebateInfo(data)
            .then(res => {
                console.log(res, "response")
                if(res &&res.status){
                    console.log(res.data, "test")
                    reject(res)
                }
                else {
                    dispatch({
                        type : types.GET_SINGLE_DEBATE, 
                        payload: res
                    })
                    resolve(res)
                }
            })
        })
    }

export const saveSearchDebateList = (data) => dispatch => {
    console.log( data, "debates")
    return dispatch({
        type : types.GET_DEBATES, 
        payload: data
    })
}
            

export const getTopicList = () => dispatch => getTrendingTopics()
    .then(res => {
        if(res && res.status){
            return res.data        
        }
        else{
            dispatch({
                type : types.GET_TOPICS, 
                payload: res
            })
        }
    })

export const saveSearchText = (text) => dispatch => {
    return dispatch({
        type: types.SAVE_SEARCH_TEXT, 
        payload: text
    })
} 

export const saveDebateInfo = (data) => dispatch => {
    return dispatch({
        type: types.SAVE_DEBATE_INFO, 
        payload: data
    })
}

export const getDebateFormat = (data) => dispatch => getDebateFormatInfo(data)
    .then(res => {
        if(res && res.status){
            console.log(res.data, "test")
        }
        else{
            dispatch({
                type : types.GET_DEBATE_FORMAT, 
                payload: res
            })
        }
    })