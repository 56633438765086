import React from 'react'


const ThanksBlock = (props) => {
    return(
        <section className="join-debate-world profile-main">
			<div className="container">
				<div className="row padding-l-r pattern-bg">
					<div className="col-12 col-lg-9 mx-auto d-flex align-items-center justify-content-center" style={{ position: 'relative', zIndex: 0 }}>
						<div className="profile_section w-100 d-flex align-items-center">
							<div className="profile-inner notice">
								<h5>Thank you for adding your students<br/> He/She/They are all set to start debating.</h5>
                                <button onClick={() => props.history.push('/profile')} 
                                    className="btn">Motivate your students by being a <i>debater</i></button>
								<button style={{ 'margin' : '5px' }}onClick={() => props.history.push('/upcoming_debates')} className="btn">Skip</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}

export default ThanksBlock