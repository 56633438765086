import React, { useEffect, useState } from 'react'
import PageLoader from 'react-loader-advanced'
import { acceptDebate , declineDebate } from '../../api/debate'
import { toast} from 'react-toastify'

function  StatusView (props) {
    const [ fetching, setFetching] = useState(true)
    const [ apiError, setApiError] = useState('')
    const [ apiSuccess, setApiSuccess] = useState('')

    useEffect(() => {
        console.log(props.location.pathname.length , props.location.pathname.split('/').length )
        let splittedPath = props.location.pathname.split('/')
        if(splittedPath[4] === 'accept'){
            acceptDebate({ 'userID': splittedPath[3], 'debateID': splittedPath[2] })
                .then(res => {
                    if(res && res.status){
                        if( typeof(res.data) === 'string'){
                            setApiError(res.data)
                            setFetching(false)
                        }
                        else {
                            setApiError(res.data.join(','))
                            setFetching(false)
                        }
                    }
                    else {
                        setApiSuccess(res)
                        setFetching(false)
                        setTimeout(() => {
                            props.history.push('/upcoming_debates')
                        }, 3000);
                    }
                })
        }
        if(splittedPath[4] === 'reject'){
            declineDebate({ 'userID': splittedPath[3], 'debateID': splittedPath[2] })
            .then(res => {
                if(res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                        setFetching(false)
                    }
                    else {
                        setApiError(res.data.join(','))
                        setFetching(false)
                    }
                }
                else {
                    setApiSuccess(res)
                    setFetching(false)
                    setTimeout(() => {
                        props.history.push('/upcoming_debates')
                    }, 3000);
                }
            })
        }

    }, [])
    return <PageLoader show={fetching} message={'Processing...'}>
                <section className="accept_debate join-debate-world">
                {apiSuccess !== '' ? <p className="notification success">{apiSuccess}</p>: null}
                {apiError !== '' ? <p className="notification alert">{apiError}</p>: null}
                    <div className="container"></div>
                </section>
            </PageLoader>
}

export default StatusView