import { apiGet, apiPost } from '../utils/api'
import { DEBATE_API_URL, AUTH_API_URL } from '../constants'


export const fetchLanguagesList = () => {
    return apiGet(DEBATE_API_URL + `debate/listingDebatLanguage`)
        .then(res => res)
        .catch(err => err.response)
}

export const fetchDebateFormats = () => {
    return apiGet(DEBATE_API_URL + `debate/listingDebatFormat`)
        .then(res => res)
        .catch(err => err.response)
}

export const getGeoInfo = () => {
    return apiGet('https://extreme-ip-lookup.com/json/')
        .then(response => response)
        .catch(err => err.response)
};

export const sendInvite = (data) => {
    return apiPost(AUTH_API_URL + `sendInvite`, data)
        .then(res => res)
        .catch(err => err.response)
}
