import React from 'react'
import { Accordion , Card, Button } from 'react-bootstrap'
import moment from 'moment';
import { formats, languages } from '../../constants'

function AcceptDebateAccordian (props) {

    const { debate, userInfo } = props
    return(
        <div className="col-lg-4 col-xs-12 mb-4">
        <Accordion>
            <Card>
                <Card.Header className="card-header p-0">   
                    <Accordion.Toggle as={Button} variant="link" eventKey={`${props.srNo}`}>
                        <h5>{debate.debate_title}</h5> 
                    </Accordion.Toggle>
                    <p className="mb-0">{debate.debate_description || 'test '}</p>			
                </Card.Header>
                <Accordion.Collapse eventKey={`${props.srNo}`}>
                    <Card.Body className="card-body p-0">
                        <ul>
                            {/* <li><strong>Challenged by:</strong> Sandeep Gupta</li> */}
                            <li> <strong>Format:</strong> {formats[debate.debate_format_title] }| <strong>Motion:</strong>{ debate.debate_motion === 'FOLLOW' ? 'Affirmative' : 'Negative'} | <strong>Language:</strong>{languages[debate.debate_language_title]} </li>
                            <li><strong>{moment(debate.debate_start_datetime).format('YYYY-MM-DD')} {debate.debate_start_time}</strong></li>
                            { !(userInfo.sso_id === debate.sso_id ) ? <li><strong><button className="btn" onClick={() => props.accept(debate.id)}>Accept</button> | <button className="btn" onClick={() => props.decline(debate.id)}>Decline</button></strong></li> : null}
                        </ul>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </div>
    )
}

export default AcceptDebateAccordian
	