import React from 'react'
import { Accordion , Card, Button } from 'react-bootstrap'


const faqs = [
    {
        'srNo': 1, 
        'title': 'What’s the minimum age to debate on Debatenow?',
        'description': 'Anyone above 13 years of age can create an account on Debatenow. If you are below 13, we will ask for parental consent before your account becomes operational.'
    },
    {
        'srNo': 2, 
        'title': 'Is Debatenow free to use?',
        'description': 'You can register for free on Debatenow. We currently allow a user to schedule and participate in up to three debates per month for free. We will charge a monthly subscription fee for users interested in participating in more than three debates a month.'
    },
    {
        'srNo': 3, 
        'title': 'Do I need to have a Zoom, Google Meet, or Microsoft Team account to debate on the platform?',
        'description': 'No, you don’t need any video conference service account to debate on the platform. All debates will occur on the Debatenow platform.'
    }, 
    {
        'srNo': 4, 
        'title': 'Can I debate with people my age?', 
        'description': 'You can choose to debate with a debater of your proficiency level. We currently have three debater proficiency levels - beginner, intermediate, and advanced.'
    }, 
    {
        'srNo': 5, 
        'title': 'Do you organize competitions?', 
        'description': 'Our platform matches you with debaters on a topic of common interest. We currently don’t organize live competitions on the platform. However, it is something we are considering for the future. Debatenow members can organize private competitions within their own groups.'
    },
    {
        'srNo': 6, 
        'title': 'I am a non-English speaker. Can I still debate on your platform?',
        'description': 'Yes, you can debate in any language provided there is another debater available to debate in your language.'
    },
    {
        'srNo': 7, 
        'title': 'How many debating formats does DebateNow support?',
        'description': 'You can choose to debate in two well-established formats - Lincoln-Douglas debates and Public Forum debates. You can use the ‘Design your own debate’ option if you prefer to customize the format. '
    },
    {
        'srNo': 8, 
        'title': 'Can I register my child as a debater?',
        'description': 'Yes, you can. Once registered, we will send you an email to approve your child’s account.'
    },
    {
        'srNo': 9, 
        'title': 'I am a teacher. Can I register my students on the platform?',
        'description': 'You can invite your students to join the platform. If your student is below 13, s/he can create an account, but parental consent will be required before the account becomes operational.'
    },
    {
        'srNo': 10, 
        'title': 'How can I access my past debates? Can I download the video?',
        'description': 'We expect to add this feature pretty soon.'
    },
    {
        'srNo': 11, 
        'title': 'I have a complaint about the user experience.',
        'description': 'We are really sorry to hear that. Please write to us at help@debatenow.org. We will do everything we can to improve the experience.'
    }
]

function  Faq() {
    return(
        <section className="join-debate-world profile-main">
			<div className="container">
                {faqs.map(data => <FaqItem {...data}/>)}
        </div>
    </section>
    )
}

export default Faq 



const FaqItem = (props) => {
    return (
        <Accordion>
            <Card>
                <Card.Header className="card-header p-0">   
                    <Accordion.Toggle as={Button} variant="link" eventKey={`${props.srNo}`}>
                        <h5>{props.title}</h5> 
                    </Accordion.Toggle>			
                </Card.Header>
                <Accordion.Collapse eventKey={`${props.srNo}`}>
                    <Card.Body className="card-body p-0">
                        <p>{props.description}</p>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}