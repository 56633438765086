import React, { useState, useEffect } from 'react'
import { scheduleDebate, updateDebate, getDebateInfo } from '../api/debate'
import Loader from 'react-loader-spinner'
import moment from 'moment';
import PageLoader from 'react-loader-advanced'
import InviteJudge from '../components/debate/InviteJudge';
import InviteAudience from '../components/debate/InviteAudience';


function InviteUsers(props){
    const [judge_info, setjudgeInfo ] = useState([])
    const [audience_info, setaudienceInfo ] = useState([])
    const [debateId, setDebateId ] = useState('') 
    const [loading, setLoading] = useState(false)
    const [apiError, setApiError] = useState('')
    const [apiSuccess, setApiSuccess] = useState('')

    useEffect(() => {
        let path = props.location.pathname.split('/')
		if(path && path.length > 2){
            let debateInfo = JSON.parse(localStorage.getItem('debate-info'))
            console.log(debateInfo.audience_info, debateInfo,  "local storage")
            setjudgeInfo(debateInfo.judge_info)
            setDebateId(debateInfo.id)
            setaudienceInfo(debateInfo.audience_info)
        }
        else {
            let debateInfo = JSON.parse(localStorage.getItem('debate-info'))
            scheduleDebate({ ...debateInfo, 'judge_info': [], 'audience_info': [] })
                .then(res => {
                    if(res && res.status){
                        if( typeof(res.data) === 'string'){
                            setApiError(res.data)
                        }
                        else {
                            setApiError(res.data.join(','))
                        }
                    }
                    else {
                        //console.log(res , "res")
                        setDebateId(res.saved)
                    // localStorage.removeItem('debate-info')
                    }
                })
        }

    }, [])


    const updateDebateInfo = (info) => {
        updateDebate({ ...info, 'debateID': debateId })
                .then(res => {
                    if(res && res.status){
                        if( typeof(res.data) === 'string'){
                            setApiError(res.data)
                        }
                        else {
                            setApiError(res.data.join(','))
                        }
                        setLoading(false)
                    }
                    else {
                        setLoading(false)
                        localStorage.removeItem('debate-info')
                        localStorage.removeItem('judges')
                        localStorage.removeItem('audience')
                        setApiSuccess('Debate schedule updated successfully.')
                        
                        setTimeout(() => {
                            props.history.push('/upcoming_debates')
                        }, 2000)         
                    }
                })
    }

    const submit = () => {
        //console.log( judge_info, audience_info, "test")
        // if((Object.keys(judge).length > 0) || (Object.keys(audience).length > 0)){
            setLoading(true)
            let debateInfo = JSON.parse(localStorage.getItem('debate-info'))
            delete debateInfo.partner
            delete debateInfo.opponent
            delete debateInfo.judge_emails
            delete debateInfo.audience_emails 
            let judge_info = JSON.parse(localStorage.getItem('judges'))
            let validJudgeArr = validateInfo(judge_info)
            if(validJudgeArr && validJudgeArr.length  > 0){
                debateInfo = { ...debateInfo, 'judge_info': validJudgeArr }
            }    
            else {
                debateInfo = { ...debateInfo, 'judge_info': []}
            }
            let audience_info = JSON.parse(localStorage.getItem('audience')) || null
            let validAudienceArr = validateInfo(audience_info)
            if(validAudienceArr && validAudienceArr.length > 0 ){
                debateInfo = { ...debateInfo, 'audience_info': validAudienceArr }
            }    
            else {
                debateInfo = { ...debateInfo, 'audience_info': []}
            }          
            debateInfo = { ...debateInfo, 'debate_start_datetime' : moment(debateInfo['debate_start_datetime']).format('YYYY-MM-DD')}
            console.log(debateInfo, "debate Info")
            updateDebateInfo(debateInfo)
        // }
        // else {
        //     setApiError('Please invite Judge/s or Audience')
        // } 
    }

    const skip = () => {
        props.history.push('/upcoming_debates')
    } 

    const goToEditTiming = (id) => {
		//setFetching(true)
		return getDebateInfo({ 'debateID': id })
			.then(res => {
				if(res && res.status){
					if( typeof(res.data) === 'string'){
						setApiError(res.data)
						//setFetching(false)
					}
					else {
						setApiError(res.data.join(','))
						// setFetching(false)
					}
				}
				else {
					next(res.debate_format_title, res.debate_format_id)
				}
			})
	}

	const next = (format, id) => {
		switch(format){
			case 'custom':
				return props.history.push(`/custom_debate/${id}`)
			case 'public':
				return props.history.push(`/public_debate/${id}`)
			case 'lincoln':
				return props.history.push(`/ld_debate/${id}`)
			default: 
				return null
		}
    }


    const validateInfo = (arr) => {
        if((arr !== null) && (arr.length > 0)){
            let verify = arr.map(item => {
                let objArr = Object.values(item)
                let filtered = objArr.filter(item => item) || []
                if(filtered.length > 0){
                    return item
                }
                else {
                    return false
                }
            })
           
            if(verify.includes(false)){
               // setLoading(false)
               return setApiError('Please fill judge/audience info.')
            }
            else {
                return arr 
            }
        } 
        else {
            console.log(arr, "passed arr")
        }
    }


    return(
        <PageLoader show={loading} message={'Processing....'}>
        <section className="join-debate-world profile-main">
			<div className="container">
				<div className="row padding-l-r pattern-bg">
					<div className="col-12 col-lg-10 mx-auto d-flex align-items-center justify-content-center" style={{ position: 'relative', zIndex: 0 }}>
						<div className="profile_section w-100">
                            {apiSuccess !== '' ? <p className="notification success">{apiSuccess}</p>: null }
                            {apiError !== ''? <p className="notification alert">{apiError}</p>: null}
							<div className="profile-inner invite-box">
                                <form>
                                    <div className="scroll_box">
                                        <div className="row">
                                            <InviteJudge setApiError={setApiError} validateInfo={validateInfo}judge_info={judge_info}/>
                                            <InviteAudience setApiError={setApiError} validateInfo={validateInfo} audience_info={audience_info} />
                                        </div>
                                    </div>											
                                    <div className="row">
                                        <div className="col-sm-5 mx-auto">                                                                                                
                                            <div className="divide">
                                            <button className="btn" type="button" onClick={submit}>
                                                {loading ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30} /> : 'Submit'}</button>
                                                <button className="btn" type="button" onClick={skip}>Skip</button>
                                            </div>                                                 
                                        </div>												
                                    </div>	
                                    <div className="row">
                                        <div className="col-sm-5 mx-auto"> 
                                            <a className="back-btn" onClick={() => goToEditTiming(debateId)}>back</a>
                                        </div>
                                    </div>								
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </PageLoader>
    )
}

export default InviteUsers


