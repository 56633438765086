import microValidator from 'micro-validator' 

function checkContains (value) {
    return value.includes('zoom')
}

microValidator.addRule('contains', checkContains)

export const validate = (data = {}) => {
        const errors = microValidator.validate({
            debater_bio : {
                required: {
                    errorMsg: `Bio is required`
                }
            },
            debater_primary_language:{
                required: {
                    errorMsg: `Primary language is required`
                }
            }, 
            debater_secondry_language:{
                required: {
                    errorMsg: `Secondary language is required`
                }
            },
            // debater_zoom_link: {
            //     required: {
            //         errorMsg: `Zoom link is required`
            //     }, 
            //     validUrl: {
            //         errorMsg: `Please enter a valid url`
            //     }, 
            //     contains : {
            //         errorMsg: `Please enter valid zoom url`
            //     }
            // } 

        }, data)
        
       return errors
}


