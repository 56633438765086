import React, { useEffect, useState,  } from 'react'

const user = { 'name': '', 'email': '' }

function  InviteJudge(props) {
    const [judge_info, setjudgeInfo] = useState(props.judge_info || [])
    
    const addJudge = (index) => {
        if(judge_info.length < 2){
            let arr = [...judge_info]
            arr.push(user);
            setjudgeInfo(arr)
        }
        else {
           props.setApiError('Upto two jugde/s can be invited.')
        }
        
    }
    useEffect(() => {
        if(judge_info.length === 0 && judge_info.length < 1){
            addJudge(0)
        }
        props.setApiError('')
    }, [props, judge_info])

    const onChangeJudgeInfo = (key, value, index) => {
        let arr = [...judge_info]        
        arr[index] = {...arr[index], [key] : value }
        
        setTimeout(() => {
            localStorage.setItem('judges', JSON.stringify(arr))
        }, 1000);
        
        setjudgeInfo(arr)
    }

    const remove = (index) => {
        let arr = [...judge_info]
        arr.splice(index, 1)
        setjudgeInfo(arr)
        setTimeout(() => {
            localStorage.setItem('judges', JSON.stringify(arr))
        }, 1000); 
    }
    
    return(
        <div className="col-lg-6">
            <div className="row">
                <div className="col-10 ml-auto">																
                    <h2 className="text-center">Invite judge <span className="opt-line">(Upto two names/emails)<br />(Optional)</span> </h2>
                </div>
            </div>
            {judge_info.map((info, index) => {
               return <div>
                    {(index > 0) ? <span onClick={() => remove(index)}>X</span> : null}
                <div className="form-group row">
                    <label for="username" className="col-sm-2 col-form-label text-right">Name</label>
                    <div className="col-sm-10">
                        <input 
                            value={info.name || ''} 
                            type="text" 
                            className="form-control" 
                            id="username" 
                            placeholder="" 
                            onChange={(e) => onChangeJudgeInfo('name', e.target.value, index)}/>
                    </div>
                </div>	
                <div className="form-group row">
                    <label for="username" className="col-sm-2 col-form-label text-right">Email</label>
                    <div className="col-sm-10">
                        <input 
                            value={info.email || ''} 
                            type="text" 
                            className="form-control" 
                            id="username" 
                            onChange={(e) => onChangeJudgeInfo('email', e.target.value, index)} 
                            placeholder=""/>
                        {/* <button className="btn invite-add-btn" type="button" onClick={addAudience}>+ Add audience</button> */}
                    </div>
                </div>
            </div> })}
            {!(judge_info.length === 2) ?  <div className="form-group row">
                <div className="col-sm-10">
                    <button className="btn invite-add-btn" type="button" onClick={ () => addJudge(judge_info.length)}>+ Add judge</button>
                </div>
            </div> : null }
        </div>
    )
}

export default InviteJudge 