export const AUTH_API_URL = `https://us-central1-debatenow-8f750.cloudfunctions.net/auth/api/`
export const DEBATE_API_URL = `https://us-central1-debatenow-8f750.cloudfunctions.net/debate/api/`

export const proficiency = [
    { 
        'label': 'Beginner',
        'value': 'BEGINNER'
    },
    { 
        'label': 'Intermediate',
        'value': 'INTERMEDIATE'
    },
    { 
        'label': 'Advanced',
        'value': 'EXPERT'
    }
]

export const formats = {
    'public': 'Public', 
    'lincoln': 'Lincoln Dougles', 
    'custom': 'Custom'
}

export const languages = {
    'hindi': 'Hindi', 
    'english': 'English'
}

export const motions = {
    'follow' : 'FOLLOW', 
    'against': 'AGAINST'
}

//7Awc0yvbcMKgpp2rRm04

//PlNvkhRWF0EqBEC4Fcrq 