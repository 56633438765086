import * as types from '../action_types/auth'


let initialState = {
    loggedInUser : {}
}

const Login =  (state = initialState, action) => {
    switch (action.type) {
        case types.SAVE_LOGGED_IN_USER:
            console.log(action.payload, "logged in ")
            return { ...state, loggedInUser: action.payload }
        default:
            return state
    }
}

export default Login