import React, { useState, useEffect } from 'react'
import { validate } from '../../validations/addChild'

function  AddChild(props) {
    const [ day, setDay] = useState('')
    const [ month, setMonth] = useState('')
    const [ year, setYear] = useState('')
    const [ child, setChild] = useState({})
    const [ errors, setErrors] = useState({})
    
    useEffect(() => {
        let dob = `${year}-${month}-${day}`
        if( dob !== '--' ){
            onChangeChildInfo('dob', dob)
            //console.log({...child, 'dob': dob } , "child in add child")
        }
    }, [day, month, year])
    
    let days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
	let months = [
        { 'value':1, 'label':'Jan'},
        { 'value':2, 'label':'Feb'},
        { 'value':3, 'label':'Mar'},
        { 'value':4, 'label':'Apr'},
        { 'value':5, 'label':'May'},
        { 'value':6, 'label':'Jun'},
        { 'value':7, 'label':'Jul'},
        { 'value':8, 'label':'Aug'},
        { 'value':9, 'label':'Sep'},
        { 'value':10, 'label':'Oct'},
        { 'value':11, 'label':'Nov'},
        { 'value':12, 'label':'Dec'},
    ]
	let yearValues = [1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019]
   

    const onChangeChildInfo = (key, value) => {
        //setErrors({})
        let arr = [...props.children]
        setChild({...child, [key] : value })
        // let validResult = validate({...child})
        // if( Object.keys(validResult).length > 0 ){
        //     setErrors(validResult)
        // }
        // else {
        arr[(props.srNo - 1)] = {...child }
        props.setMultiple(arr)
        //}
    }

    const remove = (index) => {
        let arr = [...props.children]
        arr.splice(index, 1)
        props.setMultiple(arr)
    }
   
    return(
        <div className="scroll_box child-box mb-0">
            <div className="form-group row">
                <div className="col-sm-8 ml-auto">
                    <h4>Child {props.srNo}</h4>
                    {(props.srNo > 1) ? <button type="button" onClick={() => {
                        remove(props.srNo - 1)
                    }}>X</button> : null}
                </div>
            </div>
            <div className="form-group row">
                <label for="username" className="col-sm-4 col-form-label text-right">Email ID<sup>*</sup></label>
                <div className="col-sm-8">
                    <input onChange={(e) => onChangeChildInfo('username', e.target.value )}
                        type="text" className="form-control" id="username" placeholder="Enter Email ID"/>
                    {errors['username'] && errors['username'].length > 0 ? <p className="alert">{errors['username'][0]}</p> : null}
                </div>        
            </div>
            {/* <div className="form-group row">
                <label for="username" className="col-sm-4 col-form-label text-right">Username</label>
                <div className="col-sm-8">
                    <input onChange={(e) => props.setChildInfo({...props.info, 'username' : e.target.value })} 
                        type="text" className="form-control" id="username" placeholder="username"/>
                </div>
            </div> */}
            <div className="form-group row">
                <label for="username" className="col-sm-4 col-form-label text-right">Password<sup>*</sup></label>
                <div className="col-sm-8">
                    <input onChange={(e) => onChangeChildInfo('password', e.target.value )} 
                        type="password" className="form-control" id="username" placeholder="password"/>
                    {errors['password'] && errors['password'].length > 0 ? <p className="alert">{errors['password'][0]}</p> : null}
                </div>
                
            </div>
            <div className="form-group row">
                <label for="language" className="col-sm-4 col-form-label text-right">DOB<sup>*</sup></label>
                <div className="col-sm-8 dob">
                    <div className="row">
                        <div className="col">
                            <select name="month" className="form-control" id="month" onChange={(e) => setMonth(e.target.value)}>
                                <option key={0}  value={''}>Month</option>
                                {months.map(month => <option value={month.value}>{month.label}</option>)}
                            </select>
                        </div>
                        <div className="col">
                            <select name="date" className="form-control" id="date" onChange={(e) => setDay(e.target.value)}>
                                <option key={0}  value={''}>Day</option>
                                {days.map(day => <option value={day}>{day}</option>)}
                            </select>
                        </div>
                        <div className="col">
                            <select name="year" className="form-control" id="year" onChange={(e) => setYear(e.target.value)}> 
                                <option key={0} value={''}>Year</option>
                                {yearValues.map(year => <option value={year}>{year}</option>)}
                            </select>
                        </div>
                    </div>
                    {errors['dob'] && errors['dob'].length > 0 ? <p className="alert">{errors['dob'][0]}</p> : null}
                </div>
            </div>												
            <div className="form-group row">
                <label for="username" className="col-sm-4 col-form-label text-right">School<sup>*</sup></label>
                <div className="col-sm-8">
                    <input onChange={(e) => onChangeChildInfo('school', e.target.value )} 
                        type="text" className="form-control" id="username" placeholder="school info"/>
                    {errors['school'] && errors['school'].length > 0 ? <p className="alert">{errors['school'][0]}</p> : null}
                </div>
            </div>											
            <div className="form-group row">
                <label for="username" className="col-sm-4 col-form-label text-right">Topics of interest</label>
                <div className="col-sm-8">
                    <input onChange={(e) => onChangeChildInfo('topics', e.target.value )} 
                        type="text" className="form-control" id="interest" placeholder=""/>
                    {errors['topics'] && errors['topics'].length > 0 ? <p className="alert">{errors['topics'][0]}</p> : null}
                    {/* <small className="zoom-link"><b><a href="#">Add Zoom Pro</a> or <a href="#">Subscribe to DebatePro</a></b> (Zoom included)</small> */}
                </div>
            </div>
        </div>
    )
}

export default AddChild