import React, { useEffect, useState } from 'react'


function ThanksPage (props) {
	const [ show, setShow ] = useState(true)
	const [ showText, setShowText] = useState(false)

	useEffect(() => {
		let info = localStorage.getItem('child')

		if(info === 'false'){
			setShow(false)
		}

	}, [])

	const skip = () => {
		if(show){
			props.history.push('/learn')
		}
		else {
			props.history.push('/upcoming_debates')
		}
	}


    return(
        <section className="join-debate-world profile-main">
			<div className="container">
				<div className="row padding-l-r pattern-bg">
					<div className="col-12 col-lg-9 mx-auto d-flex align-items-center justify-content-center" style={{ position: 'relative', zIndex: 0 }}>
						<div className="profile_section w-100 d-flex align-items-center">
							<div className="profile-inner notice">
								{show ? <h5>Thank you for adding your child</h5> : null}
                                <button onClick={() => props.history.push('/profile')} 
                                    className="btn">Motivate your child by being a <i>debater</i></button>
									<button style={{ 'margin' : '5px' }} onClick={skip} className="btn">Skip</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}

export default ThanksPage


//<h5>If you want to learn more before adding a child  <a className="back-btn"href="/faq">click here</a></h5>