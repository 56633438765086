import { apiPost } from '../utils/api'
import { AUTH_API_URL } from '../constants'

export const checkUserEmail = (data) => {
   return  apiPost(AUTH_API_URL + `checkemail`, data, true)
        .then(res => res)
        .catch ( err => err.response)
}

export const login = (data) => {
    return  apiPost(AUTH_API_URL +`auth/login`, data)
        .then(res => res)
        .catch ( err => err.response)
}

export const signup = (data) => {
    return  apiPost(AUTH_API_URL +`auth/signup`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const verifyUsername = (data) => {
    return apiPost(AUTH_API_URL + `auth/checkUsername`, data)
        .then(res => res)
        .catch ( err => err.response)
}

export const updateUserProfile = (data) => {
    return apiPost(AUTH_API_URL + `auth/updateUserInfo`, data)
        .then(res => res)
        .catch (err => err.response)
}

export const socialLogin = (data) => {
    return apiPost(AUTH_API_URL + `auth/socialApi`, data)
        .then(res => res)
        .catch(err => err.response)
}


export const skipEditProfileAPI = (data) => {
    return apiPost(AUTH_API_URL + `auth/skipped`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const addChildAPI = (data) => {
    return apiPost(AUTH_API_URL + `auth/addChildUser`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const changePasswordAPI = (data) => {
    return apiPost(AUTH_API_URL + `changePassword`, data)
        .then(res => res)
        .catch(err => err.response)
}


export const resetPassword = (data) => {
    return apiPost(AUTH_API_URL + `resetPassword`, data)
        .then(res => res)
        .catch(err => err.response)
}

export const forgotPassword = (data) => {
    return apiPost(AUTH_API_URL + `forgotPassword`, data)
        .then(res => res)
        .catch(err => err.response)
}



