import React, { useState, useEffect } from 'react';
import DateTime from 'react-datetime'
import { fetchLanguagesList , fetchDebateFormats } from '../api/common';
import { getDebateInfo, updateDebate } from '../api/debate';
import { validate } from '../validations/debate'
import CustomAndClosed from '../components/debate/CustomAndClosed'
import LDandClosed from '../components/debate/LDandClosed'
import PublicAndClosed from '../components/debate/PublicAndClosed'
import PublicAndOpen from '../components/debate/PublicAndOpen'
import moment from 'moment'
import Loader from 'react-loader-spinner';
import PageLoader from "react-loader-advanced";
import { motions } from "../constants";

function EditDebate (props) {
    const [ info, setInfo ] = useState({})
	const [ formats, setFormats] = useState([])
	const [ langs, setLangs] = useState([])
	const [ errors, setErrors] = useState({})
	const [ loading, setLoading] = useState(false)
	const [ loadInfo, setloadInfo] = useState(false)
	const [ apiError, setApiError] = useState('')

	useEffect(() => {
		let path = props.location.pathname.split('/')
		if(path && path.length > 2){
            setloadInfo(true)
            getDebateInfo({ 'debateID' : path[path.length - 1] })
                .then(res => {
                    if(res && res.status){
                        if( typeof(res.data) === 'string'){
                            setApiError(res.data)
                        }
                        else {
                            setApiError(res.data.join(','))
                        }
                        setloadInfo(false)
                    }
                    else {
						let debateInfo = res 
						//console.log( res, "res of debate")

                        debateInfo = {
							...debateInfo, 
                            'debate_motion': motions[debateInfo.debate_motion], 
							'debate_format_title' : setFormat(debateInfo.debate_format_title),
							'debate_language': (debateInfo.debate_language_title === 'hindi' ) ? 'WdHk3gKfs5iD6NR2Erl8' : 'language_id'
                        }
                        setInfo(debateInfo)
                        setloadInfo(false)
                    }
                })
		}
		setErrors({})
		if(!(langs && langs.length > 0)){
			fetchLanguagesList()
				.then(res => setLangs(res))
		}

    }, [langs])
    

    const setFormat = (format) => {
        switch(format){
            case 'public': 
                return 'PUBLIC'
            case 'lincoln': 
                return 'LINCOLN'
            case 'custom': 
                return 'CUSTOM'
        }
    } 

	const renderInviteEmailView = () => {
		if((info.debate_format_title === 'LINCOLN') && (info.debate_type === 'CLOSED')){
			return <LDandClosed updateInfo={setInfo} info={info}/>
		}	
		else if((info.debate_format_title === 'PUBLIC') && (info.debate_type === 'OPEN')){
			return <PublicAndOpen updateInfo={setInfo} info={info}/>
		}
		else if((info.debate_format_title === 'PUBLIC') && (info.debate_type === 'CLOSED')){
			let obj = {}
			let mails = info.opponent_emails.forEach((element, index) => {
                obj = { ...obj, [`mail${index + 1}`]: element }
                //console.log(obj, "obj")
                return obj
            });
			return <PublicAndClosed updateInfo={setInfo} info={info} opponent_emails={obj}/>
		}
		else if((info.debate_format_title === 'CUSTOM') && (info.debate_type === 'CLOSED')){
			let partnerObj = {}
            let partner_mails = info.partner_emails.forEach((element, index) => {
                partnerObj = { ...partnerObj, [`p${index + 1}`]: element }
               // console.log(partnerObj, "obj")
                return partnerObj
			});
			let opponentObj = {}
            let opponent_mails = info.opponent_emails.forEach((element, index) => {
                opponentObj = { ...opponentObj, [`O${index + 1}`]: element }
                //console.log(opponentObj, "obj")
                return opponentObj
            });
            //console.log(partner_mails, opponent_mails, "opponent")
			return <CustomAndClosed 
						updateInfo={setInfo} 
						info={info} 
						partnerObj={partnerObj}  
						opponentObj={opponentObj}
					/>
		}	
	}

	const validateEmail= (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

    const submit = () => {
		let storageInfo = JSON.parse(localStorage.getItem('user'))
		let validResult = validate(info)
		let result1 = [], result2 = []
		if( Object.keys(validResult).length > 0 ){
			setErrors(validResult)
		}
		else {
			setLoading(true)
			if(info.partner_emails && (info.partner_emails.length > 0 )){
				result1 = info.partner_emails.map( email => validateEmail(email))
				if(result1.includes(false)) {
					setLoading(false)
					setApiError('Please add valid partner email/s')
					return; 
				}
			}
			else if(info.opponent_emails && (info.opponent_emails.length > 0 )){
				result2 = info.opponent_emails.map( email => validateEmail(email))
				if (result2.includes(false)){
					setApiError('Please add valid opponent email/s')
					setLoading(false)
					return;
				}
			}
			//console.log(info, "info")
			localStorage.setItem( 'debate-info', JSON.stringify({ ...info, 'sso_id': storageInfo.sso_id }))

			updateDebateInfo({ ...info, 'debate_start_datetime' : moment(info['debate_start_datetime']).format('YYYY-MM-DD')})
			
			setLoading(false)
			
		}
	}


	const updateDebateInfo = (info) => {
        updateDebate({ ...info, 'debateID': info.id })
                .then(res => {
                    if(res && res.status){
                        if( typeof(res.data) === 'string'){
                            setApiError(res.data)
                        }
                        else {
                            setApiError(res.data.join(','))
                        }
                        setLoading(false)
                    }
                    else {
                        setLoading(false)
                       	// localStorage.removeItem('debate-info')
                        //setApiSuccess('Debate schedule updated successfully.')
                        
                        next(info.debate_format_id, info.debate_format_title)         
                    }
                })
	}
	
	

	
	const next = (id, title) => {
        //console.log(id, title, "test")
		switch(title){
			case 'CUSTOM':
				return props.history.push(`/custom_debate/${id}`)
			case 'PUBLIC':
				return props.history.push(`/public_debate/${id}`)
			case 'LINCOLN':
				return props.history.push(`/ld_debate/${id}`)
			default: 
				return null
			}
        }


	const yesterday = moment().subtract( 1, 'day' );
	const isValidDate =  current => {
		return current.isAfter( yesterday );
	};

    return (
        <section className="join-debate-world profile-main">			
			<div className="container">
				<div className="row padding-l-r pattern-bg">
					<div className="col-12 col-lg-9 mx-auto d-flex align-items-center justify-content-center" style={{ position: "relative", zIndex: 0 }}>
						<div className="profile_section w-100">
							<PageLoader show={loadInfo} message={'Loading....'}>
							{apiError !== ''? <p className="notification alert">{apiError}</p>: null}
							<div className="profile-inner">	
								<h2 className="text-center">Schedule Debate</h2>
                                <form>
									<div className="scroll_box">
										<div className="form-group row">
											<label for="bio" className="col-sm-4 col-form-label text-right">Topic</label>
											<div className="col-sm-8">
												{/* <input type="text" className="form-control form-group" placeholder="Lorem Ipsum is simply dummy text"/> */}
												<input  value={info.debate_title}
													onChange={(e) => setInfo({ ...info, 'debate_title': e.target.value }) } colSpan="5" type="text" className="form-control" id="bio" placeholder="Debate Title"></input>
												{errors['debate_title'] && errors['debate_title'].length > 0 ? <p className="alert">{errors['debate_title'][0]}</p> : null}
											</div>
										</div>

										<div className="form-group row">
											<label for="bio" className="col-sm-4 col-form-label text-right">Description</label>
											<div className="col-sm-8">
												{/* <input type="text" className="form-control form-group" placeholder="Lorem Ipsum is simply dummy text"/> */}
												<textarea  value={info.debate_description}
													onChange={(e) => setInfo({ ...info, 'debate_description': e.target.value }) } colSpan="5" type="text" className="form-control" id="bio" placeholder="Describe in 160 words or less"></textarea>
												{errors['debate_description'] && errors['debate_description'].length > 0 ? <p className="alert">{errors['debate_description'][0]}</p> : null}
											</div>
										</div>
													
										<div className="form-group row">
											<label for="account" className="col-sm-4 col-form-label text-right">Motion</label>
											<div className="col-sm-8 zomm-account">
												<div className="form-group mb-0">
													<div className="radio-box radio-box2">
														<div className="box">
															<div className="form-check">
																<input onChange={(e) => setInfo({ ...info, 'debate_motion': e.target.value }) } 
																	className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="FOLLOW" checked={(info.debate_motion === 'FOLLOW') }/>
																<label className="form-check-label" for="exampleRadios1">
																	Affirmative
																</label>
															</div>
														</div>
														<div className="box">										
															<div className="form-check">
																<input onChange={(e) => setInfo({ ...info, 'debate_motion': e.target.value }) } 
																	className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="AGAINST" checked={(info.debate_motion === 'AGAINST') }/>
																<label className="form-check-label" for="exampleRadios2">
																	Negative
																</label>
															</div>
														</div>
													</div>
												</div>
												{errors['debate_motion'] && errors['debate_motion'].length > 0 ? <p className="alert">{errors['debate_motion'][0]}</p> : null}
											</div>
										</div>

										<div className="form-group row">
											<label for="language" className="col-sm-4 col-form-label text-right">Language</label>
											<div className="col-sm-8">
												<div className="row">
													<div className="col">
														<select value={info.debate_language} name="Language" className="form-control" id="Language1" onChange={(e) => setInfo({ ...info, 'debate_language' : e.target.value , 'debate_language_title': (e.target.value === 'language_id') ? 'ENGLISH' : 'HINDI'})}>
															<option value="">Select Language</option>
															{langs && (langs.length > 0 ) ?  	
																(langs.map(data => <option value={data.id}>{data.language_name}</option>)):null }
														</select>
													</div>
												</div>
												{errors['debate_language'] && errors['debate_language'].length > 0 ? <p className="alert">{errors['debate_language'][0]}</p> : null}
											</div>
										</div>		

										<div className="form-group row">
											<label for="username" className="col-sm-4 col-form-label text-right">Date</label>
											<div className="col-sm-8">
												<div className="row">
													<div className="col">
														<DateTime 
															value={info.debate_start_datetime}
															isValidDate={isValidDate}
															closeOnSelect={true}
															timeFormat={false}
															onChange={(date) => setInfo({...info, 'debate_start_datetime': moment(date._d).format('YYYY-MM-DD') })}/>
													</div>		
												</div>
												{errors['debate_start_datetime'] && errors['debate_start_datetime'].length > 0 ? <p className="alert">{errors['debate_start_datetime'][0]}</p> : null}
											</div>
										</div>

										<div className="form-group row">
											<label for="username" className="col-sm-4 col-form-label text-right">Time</label>
											<div className="col-sm-8">
												<div className="row">
													<div className="col">
														<DateTime 
															value={info.debate_start_time}
															initialViewMode={'time'}
															dateFormat={false}
															closeOnSelect={true}
															onChange={(date) => setInfo({...info, 'debate_start_time': moment(date._d).format('HH:MM A') })}/>
													</div>		
												</div>
												{errors['debate_start_time'] && errors['debate_start_time'].length > 0 ? <p className="alert">{errors['debate_start_time'][0]}</p> : null}
											</div>
										</div>
											
										<div className="form-group row">
											<label for="account" className="col-sm-4 col-form-label text-right">Format</label>
											<div className="col-sm-8 zomm-account">
												<div className="form-group mb-0">
													<div className="radio-box radio-box2">
														
														<div className="box">										
															<div className="form-check">
																<input  id="exampleRadios12" onChange={(e) => setInfo({ ...info, 'debate_format_title' : 'LINCOLN' })}  
																	className="form-check-input" type="radio" value={'LINCOLN'} checked={(info.debate_format_title === 'LINCOLN')}/>
																<label className="form-check-label" for="exampleRadios12">
																	Lincoln Douglas
																</label>
															</div>
														</div> 

														<div className="box">
															<div className="form-check">
																<input id="exampleRadios11" onChange={(e) => setInfo({ ...info, 'debate_format_title' : 'PUBLIC'  })} 
																	className="form-check-input" type="radio"  value={'PUBLIC'} checked={(info.debate_format_title === 'PUBLIC')}/>
																<label className="form-check-label" for="exampleRadios11" >
																	Public Forum
																</label>
															</div>
														</div>
														
														<div className="box">										
															<div className="form-check">
																<input  id="exampleRadios13" onChange={(e) => setInfo({ ...info, 'debate_format_title' : 'CUSTOM' })} 
																	className="form-check-input" type="radio" value={'CUSTOM'} checked={(info.debate_format_title === 'CUSTOM')}/>
																<label className="form-check-label" for="exampleRadios13">
																	Design your debate
																</label>
															</div>
														</div>
													</div>
												</div>
												{errors['debate_format_id'] && errors['debate_format_id'].length > 0 ? <p className="alert">{errors['debate_format_id'][0]}</p> : null}
											</div>
										</div>					
										<div className="form-group row">
											<label for="account" className="col-sm-4 col-form-label text-right">Proficiency</label>
											<div className="col-sm-8 zomm-account">
												<div className="form-group mb-0">
													<div className="radio-box">
														<div className="box">
															<div className="form-check">
																<input  id="exampleRadios21" onChange={(e) => setInfo({...info, 'debate_proficiency_level' : e.target.value })}  className="form-check-input" type="radio" value={'BEGINNER'} checked={(info.debate_proficiency_level === 'BEGINNER')}/>
																<label className="form-check-label" for="exampleRadios21" type="radio">
																	Beginner
																</label>
															</div>
														</div>
														<div className="box">										
															<div className="form-check">
																<input  onChange={(e) => setInfo({...info, 'debate_proficiency_level' : e.target.value })} id="exampleRadios22"
																 className="form-check-input" type="radio" value={'INTERMEDIATE'} checked={(info.debate_proficiency_level === 'INTERMEDIATE')}/>
																<label className="form-check-label" for="exampleRadios22">
																	Intermediate
																</label>
															</div>
														</div>
														<div className="box">										
															<div className="form-check">
																<input  id="exampleRadios23" onChange={(e) => setInfo({...info, 'debate_proficiency_level' : e.target.value })} className="form-check-input" type="radio" value={'EXPERT'} checked={(info.debate_proficiency_level === 'EXPERT')}/>
																<label className="form-check-label" for="exampleRadios23">
																	Advanced
																</label>
															</div>
														</div>
													</div>
												</div>
												{errors['debate_proficiency_level'] && errors['debate_proficiency_level'].length > 0 ? <p className="alert">{errors['debate_proficiency_level'][0]}</p> : null}
											</div>
										</div>
								
										<div className="form-group row">
											<label for="account" className="col-sm-4 col-form-label text-right">Type</label>
											<div className="col-sm-8 zomm-account">
												<div className="form-group mb-0">
													<div className="radio-box radio-box2">
														<div className="box">
															<div className="form-check">
																<input onChange={(e) => setInfo({...info, 'debate_type' : e.target.value })} className="form-check-input" type="radio" name="exampleRadios3" id="exampleRadios31" value={"OPEN"} checked={(info.debate_type === 'OPEN')}/>
																<label className="form-check-label" for="exampleRadios31">
																	Open debate <img src="images/quest.svg" alt=""/>
																</label>
															</div>
														</div>
														<div className="box">										
															<div className="form-check">
																<input onChange={(e) => setInfo({...info, 'debate_type' : e.target.value })} className="form-check-input" type="radio" name="exampleRadios3" id="exampleRadios32" value={"CLOSED"} checked={(info.debate_type === 'CLOSED')}/>
																<label className="form-check-label" for="exampleRadios32">
																	Closed debate <img src="images/quest.svg" alt=""/>
																</label>
															</div>
														</div>
													</div>
												</div>
												{errors['debate_type'] && errors['debate_type'].length > 0 ? <p className="alert">{errors['debate_type'][0]}</p> : null}
											</div>
											
										</div>
									</div>	
									{(info.debate_type !== '') ? renderInviteEmailView() :  null}
									<div className="col-12">										
										<div className="row">
											<div className="col-sm-4"></div>
											<div className="col-sm-8">
												<div className="row">
													<div className="col-6 mx-auto">
														<button className="btn mt-0" type="button" onClick={submit}>
														{loading ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30} /> : 'Submit'}</button>
													</div>
												</div>
											</div>												
										</div>									
										<div className="row">
											<div className="col-sm-4"></div>
											<div className="col-sm-8">
												<a className="back-btn" onClick={() => props.history.goBack()}>back</a>
											</div>
										</div>
									</div>
								</form>	
							</div>
						</PageLoader>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}


export default EditDebate
