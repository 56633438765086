import React from 'react'
import {
    BrowserRouter as Router, Switch, Route,
} from "react-router-dom";
import Signup from './containers/Signup'
import LandingPage from './containers/LandingPage'
import CreateDebate from './containers/CreateDebate'
import EditDebate from './containers/EditDebate'
import UpcomingDebates from './containers/UpcomingDebates';
import FirstPage from './containers/FirstPage';
import UserProfile from './containers/UserProfile';
import CustomDebate from './containers/CustomDebateTable';
import PublicDebate from './containers/PublicDebate';
import LDDebate from './containers/LDDebates';
import InviteUsers from './containers/InviteUsers';
import AcceptDebates from './containers/AcceptDebates';
import AccpetAll from './containers/AcceptAll';
import NotFound from './components/common/NotFound';
import AddChild from './containers/AddChild';
import InviteStudent from './containers/InviteStudent';
import TrendingTopics from './containers/TrendingTopic';
import ThanksPage from './containers/ThanksPage';
import ThanksBlock from './containers/ThanksStudent';
import PrivateRoute from './components/Route/PrivateRoute';
import StatusView from './components/common/StatusView'
import Faq from './components/common/Faq'
import ResetPassword from './components/Auth/ResetPassword';
import LearnMore from './components/Child/LearnMore';

const AppRoutes = () => {
    return (
      <Router >
        <Switch>
          <Route exact path="/" component={FirstPage}/>
          <Route path="/signup" component={LandingPage}/>
          <Route path="/signup_step2" component={Signup}/>
          <Route path="/reset-password/:token" component={ResetPassword}/>
          <PrivateRoute path="/create_debate" component={CreateDebate}/>
          <PrivateRoute path="/create_debate/:id" component={CreateDebate}/>
          <PrivateRoute path="/upcoming_debates" component={UpcomingDebates}/>
          <Route path="/search" component={UpcomingDebates}/>
          <PrivateRoute path="/edit_profile" component={UserProfile}/>
          <PrivateRoute path="/profile" component={UserProfile}/>
          <PrivateRoute path="/custom_debate" component={CustomDebate}/>
          <PrivateRoute path="/public_debate" component={PublicDebate}/>
          <PrivateRoute path="/ld_debate" component={LDDebate}/>
          <PrivateRoute path="/custom_debate/:id" component={CustomDebate}/>
          <PrivateRoute path="/edit_debate/:id" component={EditDebate}/>
          <PrivateRoute path="/public_debate/:id" component={PublicDebate}/>
          <PrivateRoute path="/ld_debate/:id" component={LDDebate}/>
          <PrivateRoute path="/invite_users" component={InviteUsers}/>
          <PrivateRoute path="/invite_users/:id" component={InviteUsers}/>
          <PrivateRoute path="/accept_debates" component={AcceptDebates}/>
          <PrivateRoute path="/accept_all" component={AccpetAll}/>
          <PrivateRoute path="/add_child" component={AddChild}/>
          <PrivateRoute path="/invite_student" component={InviteStudent}/>
          <PrivateRoute path="/trending" component={TrendingTopics}/>
          <PrivateRoute path="/thanks" component={ThanksPage}/>
          <PrivateRoute path="/learn" component={LearnMore}/>
          <PrivateRoute path="/thanks_teacher" component={ThanksBlock}/>
          <PrivateRoute path="/processDebate/:id/:id/:id" component={StatusView}/>
          <Route path="/faq" component={Faq}/>
          <Route component={NotFound}/>
        </Switch>
      </Router>)
} 

export default AppRoutes