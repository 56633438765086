import React, { useEffect, useState } from 'react';
import { resetPassword } from '../../api/auth';
import Loader from 'react-loader-spinner';

const ResetPassword = (props) => {
    const [ passwordInfo, setInfo] = useState({})
    const [ errors, setErrors] = useState({})
    const [ buttonLoading, setLoading] = useState(false)
    const [ pageLoading, setPageLoading] = useState(false)
    const [ apiError, setApiError] = useState('')
    const [ successMessage, setSuccessMessage] = useState('')

    useEffect(() => {
        let path = props.location.pathname.split('/')
        //console.log(path, "path")
        setInfo({ ...passwordInfo, 'sso_id': path[path.length - 1] })
    }, [])

    const validate = (data) => {
        if(data.password === data.confirmed){
            setErrors({})
            return data
        }
        else {
            let err = {
                'password' : 'New and Re-typed password should be same.', 
                'confirmed': 'New and Re-typed password should be same.'
            }
            setErrors(err)
            return {}
        }
    }

    const  reset = () => {
        
        let info = validate(passwordInfo)

        if(Object.keys(info).length === 3){
            setLoading(true)
            setPageLoading(true)
            delete info.confirmed
            resetPassword(info)
                .then(res => {
                    if(res && res.status){
                        if( typeof(res.data) === 'string'){
                            setApiError(res.data)
                            setLoading(false)
                            setPageLoading(false)
                        }
                        else {
                            setApiError(res.data.join(','))
                            setLoading(false)
                            setPageLoading(false)
                        }
                    }
                    else {
                        setLoading(false)
                        setPageLoading(false)
                        setApiError('')
                        setSuccessMessage('Password is changed successfully. Please login with new password.')
                    }
                })
        }
        else {
            console.log(errors)
        }
            
        
    }

    return (
        <div className="profile_section w-100">
            {successMessage !== '' ? <p className="notification success"> {successMessage}</p>: null}
            {apiError !== '' ? <p className="notification alert"> {apiError}</p>: null}
            <div className="profile-inner">
                <div className="scroll_box">
                    <div className="row">
                    <div className="col-sm-12">
                        <h2 className="text-center update-password-head mt-3">Reset Password</h2>
                        <form>
                            <div className="form-group row">
                                <label for="bio" className="col-sm-4 col-form-label text-right">New Password<sup>*</sup></label>
                                <div className="col-sm-8">
                                    <input onChange={(e) => setInfo({ ...passwordInfo, 'password': e.target.value })}  
                                        colSpan="6"
                                        value={ passwordInfo.password || ''} 
                                        type="password"
                                        className="form-control" id="bio" placeholder="New Password"></input>
                                    {errors['password'] ? <p className="alert">{errors['password']}</p> : null}
                                </div>         
                            </div>
                            <div className="form-group row">
                                <label for="bio" className="col-sm-4 col-form-label text-right">Re-type Password<sup>*</sup></label>
                                <div className="col-sm-8">
                                    <input onChange={(e) => setInfo({ ...passwordInfo, 'confirmed': e.target.value })}  
                                        colSpan="6" 
                                        type="password"
                                        value={ passwordInfo.confirmed || ''} 
                                        className="form-control" id="bio" placeholder="Re-type password"></input>
                                    {errors['confirmed'] ? <p className="alert">{errors['confirmed']}</p> : null}
                                </div>         
                            </div>
                            <div className="row">
                                <div className="col-sm-8 col-xs-12 ml-auto">    
                                    <button className="btn" type="button" onClick={reset}>
                                    {buttonLoading ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30} /> : 'Submit' }</button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default ResetPassword