import React, {useEffect, useState} from 'react'



function LDandClosed (props){
    let [ email, setEmail ] = useState(props.info.opponent_emails[0] || '')
    useEffect(() => {
        if(email){
            let mails = []
            mails.push(email)
            props.updateInfo({ ...props.info,   
                "opponent_emails": [...mails]
            })
        }
    },[email])

    return(<div className="add-fields col-lg-10 col-sm-10 col-xs-12 ml-auto">
        <div className="row">
            <div className="col">
                <div className="form-group">
                    <input  value={email}
                        className="form-control" 
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Add opponent email*"/>
                </div>
            </div>
        </div>
    </div>)
}

export default LDandClosed