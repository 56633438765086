import React from 'react'
import moment from 'moment';
import { formats, languages } from '../../constants'

function AcceptDebateSliderItem(props) {
    const { debate, userInfo } = props
    return(
        <div className="owl-item">
            <div className="item card">
                <h5>{debate.debate_title}</h5>
                <p>{debate.debate_description || 'test'}</p>
                <ul>
                    <li> <strong>Format:</strong> {formats[debate.debate_format_title] }| <strong>Motion:</strong>{ debate.debate_motion === 'FOLLOW' ? 'Affirmative' : 'Negative'} | <strong>Language:</strong>{languages[debate.debate_language_title]} </li>
                    <li><strong>{moment(debate.debate_start_datetime).format('YYYY-MM-DD')} {debate.debate_start_time}</strong></li>
                    { !(userInfo.sso_id === debate.sso_id ) ? <li><strong><button className="btn" onClick={() => props.accept(debate.id)}>Accept</button> | <button className="btn" onClick={() => props.decline(debate.id)}>Decline</button></strong></li> : null}
                </ul>
            </div>
        </div>
    )
}

export default AcceptDebateSliderItem 
