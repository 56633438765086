import React, { useEffect, useState } from 'react' 
import { searchDebates, fetchUpcomingDebates } from '../api/debate'
import { saveSearchText } from '../actions/debate'
import moment from 'moment';
import { connect } from 'react-redux';
import qs from 'query-string'
import { formats, languages } from '../constants'
import PageLoader from "react-loader-advanced";


function UpcomingDebates (props){
    const [ debates, setDebates] = useState([])
	const [ search, setSearch] = useState(false)
	const [ age, setAge] = useState(20)
	const [ userInfo, setUserInfo] = useState({})
	const [ fetching, setFetching] = useState(false)
	const [ apiError, setApiError] = useState('')
	
	useEffect(() => {
		let user = JSON.parse(localStorage.getItem('user')) || {}
		setUserInfo(user)
		if( user && user.sso_id){
			calculateAge(user)	
		}

		if(props.location && props.location.pathname === '/search'){
			setSearch(true)
			setFetching(true)
			console.log(window.location.search ,  qs.parse(window.location.search), "test in ud");
			props.saveSearch(qs.parse(window.location.search))
			searchDebates({ 'search': qs.parse(window.location.search).s })
				.then(res => {
					if(res && res.status){
						//console.log(res.data)
						if( typeof(res.data) === 'string'){
							setApiError(res.data)
							setFetching(false)
						}
						else {
							setApiError(res.data.join(','))
							setFetching(false)
						}
					}
					else {
						if( res && res.length > 0){
							//props.saveDebates(res)
							setDebates(res)
							setFetching(false)
						}
						else {
							setFetching(false)
							setApiError('No Search result found.')
						}
					}	
				})
		}
		else{
			if( user && user.sso_id) {
				setFetching(true)
				fetchUpcomingDebates({ 'sso_id' : user.sso_id })
					.then(res => {
						if(res && (res.length > 0) ){
							// let arr = res.filter(res => res.accepted_by ) || []
							// //console.log(arr,"filtered")
							setDebates(res)
						} 
						
						setFetching(false)
					})
		}
			}
			
    }, [props])

	const calculateAge = (user) => {
		let date = moment(user.sso_dob).format('YYYY-MM-DD')
		let years = moment().diff(date , 'years',false);
		console.log( years, "years")
		setAge(years)
	}
    const scheduleDebate = () => {
        props.history.push('/create_debate')
	}
	
	const acceptDebate = () => {
		props.history.push('/accept_debates')
	}

	const titleCase = (str) => {
		str = str.toLowerCase().split(' ');
		for (var i = 0; i < str.length; i++) {
		  str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
		}
		return str.join(' ');
	}


	const renderImage = () => {
		let role = userInfo && userInfo.sso_role
		switch(role){
			case 'PARENT':
				return  <img src="images/child.jpg" alt="debate-upcomming" />
			case 'TEACHER':
				return <img src="images/parent.jpg" alt="debate-upcomming" />
			default:
				if(age > 13){
					return <img src="images/debate-vector-3.png" alt="debate-upcomming" />
				}
				else {
					return <img src="images/debate-vector-5.png" alt="debate-upcomming" />
				}
		}
			
	}

	const goToEditTiming = (id) => {
		setFetching(true)
		props.history.push(`/edit_debate/${id}`)
		setFetching(false)
	}

	const next = (format, id) => {
		setFetching(false)
		switch(format){
			case 'custom':
				return props.history.push(`/custom_debate/${id}`)
			case 'public':
				return props.history.push(`/public_debate/${id}`)
			case 'lincoln':
				return props.history.push(`/ld_debate/${id}`)
			default: 
				return null
			}
		}
	
	const goToEdit = (id) => {
		goToEditTiming(id)
	} 

	const joinMeeting = () => {
		if(userInfo && userInfo.sso_id){
			window.location = "https://debatenowzoom.trigma.in/"
		}
		else {
			setApiError('Please login before joining meeting')
			setTimeout(() => {
				props.history.push('/')
			}, 2000)
		}
	}
	
    return (    
		<PageLoader show={fetching} message={'Loading.....'}>
        <section className="join-debate-world">
			<div className="container">
				<div className="row">
					<div className="col-12 col-lg-5">
						<div className="join-debate-img">
							{renderImage()}
						</div>
					</div>
			
			        <div className="col-12 col-lg-7 d-flex justify-content-center">
				        <div className="join-debate-content upcoming_box">
                            {!search ? <div className="debate_btn">
                                <button className="btn" type="button" onClick={scheduleDebate}>Schedule Debate</button>
                                <button className="btn" type="button" onClick={acceptDebate}>Accept Debate</button>
                            </div> : null }
							{apiError !== ''? <p className="notification alert">{apiError}</p>: null}
					        {search ? <h1>Top 10 Results</h1> : <h1>Your Upcoming Debates</h1>}
							{debates && (debates.length > 0) ? 
								<div className="topic"> 
									<DebateList 
										debates={debates} 
										{...props} 
										titleCase={titleCase} 
										goToEditTiming={goToEditTiming}
										goToEdit={goToEdit}
										joinMeeting={joinMeeting}
										userInfo={userInfo}/>
								</div>
							  : <p>No debate. <a href="/create_debate">Schedule</a></p>}
					
				        	{/* <a className="back-btn" onClick={() => props.history.goBack()}>back</a> */}
				        </div>							
			        </div>
					
				</div>
			</div>
		</section> 
	</PageLoader>)
}


const  DebateList = ({debates, ...rest}) => 
	{
		return (debates.map((debate, index) => {
					console.log(rest.userInfo && (rest.userInfo.sso_id ===  debate.sso_id)  , "test")
					return <div className="box">
								<h6>{rest.titleCase(debate.debate_title)}</h6>
								<p>{debate.debate_description}</p>
								<div className="content">
									<p><strong>Motion:</strong>{ (debate.debate_motion === 'FOLLOW') ? 'Affirmative' : 'Negative'} | <strong>Language:</strong> {languages[debate.debate_language_title]} | <strong>Format:</strong> {formats[debate.debate_format_title]} | <strong>{moment(debate.debate_start_datetime).format('YYYY-MM-DD')} {debate.debate_start_time}</strong></p>
									{rest.userInfo && (rest.userInfo.sso_id ===  debate.sso_id) ? <p onClick={() => rest.goToEditTiming(debate.id)}><strong><u>Edit</u> </strong></p> : null}
									<button className="btn" onClick={rest.joinMeeting}>Join Meeting</button>
								</div>
								
							</div>}))
			}
					

const mapStateToProps = state => {
	return {
		debateList: state.debateReducer.debateList
	}
}

const mapDispatchToProps = dispatch => {
	return { 
		saveSearch : (data) => dispatch(saveSearchText(data))
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(UpcomingDebates);