import React, { useEffect, useState  } from 'react';
import { signup } from '../api/auth';
import { getGeoInfo } from '../api/common';
import Loader from 'react-loader-spinner';
import { validate } from '../validations/signup'
import moment from 'moment';
import PageLoader from 'react-loader-advanced'


const debater_info = {
	"name":"",
	"username":"",
	"debater_bio":"",
	"debater_proficiency_level":"",
	"debater_zoom_link":"",
}

function Signup (props) {
	const [ info, setInfo] = useState({})
	const [ message, setMessage] = useState('User successfully signed up, Now Please login.')
	const [ loading, setLoading] = useState(false)
	const [ errors, setErrors] = useState({})
	const [ c_name, setCountry] = useState('')
	const [ age, setAge] = useState(5)
	const [ user, setUser] = useState({})
	const [ created, setCreated] = useState(false)
	const [ apiError, setApiError] = useState('')
	const [ show_P, setShowPassword] = useState(false)
	const [ show_C, setShowC_Password] = useState(false)

	useEffect(() => {
		//setMessage('')
		setErrors({})
		let userInfo = JSON.parse(localStorage.getItem('info'))
		//	window.navigator.geolocation.getCurrentPosition((position) => codeLatLng(position.coords.latitude, position.coords.longitude ))
		setUser(userInfo)

		if(!(Object.keys(info).length > 0)){
			getGeoInfo()
			.then(res => {
				if(res){
					//console.log(res, "location")
					setCountry(res.country)
				}				
			})
		}
		let age = Number(localStorage.getItem('age'))
		setAge(age)
	}, [info, c_name])


	const validatePassword = (data) => {
		if(data.password === data.c_password){
           return {}
        }
        else {
            let err = {
                'c_password': 'New and confirmed password should be same.'
            }
            return err
        }
	}

	const submit = () => {
		//setMessage('')
		let storageInfo = JSON.parse(localStorage.getItem('info'))
		let data = validatePassword({ ...info })
		let validResult = validate({ ...info, age })
		//console.log( validResult, "valid Result")
		if(Object.keys(data).length > 0){
			setErrors(data)
		}
		else if(Object.keys(validResult).length > 0 ){
			setErrors({...errors , ...validResult})
		}
		else {
			setLoading(true)
			//console.log((info.parent_email === undefined), "test");
			let signupData =  (info.parent_email === undefined) ? { ...info , 'parent_email' : ''} : { ...info}
			signup({ ...signupData, ...debater_info, 'role': storageInfo.role, 'dob': moment(storageInfo.dob).format('YYYY-MM-DD') })
				.then(res => {
					if(res.saved){	
						setInfo({})
						setCreated(true)
						setApiError('')
						//toast.success('User successfully signed up, Now Please login')
					}
					else if(res && res.status){
						if( typeof(res.data) === 'string'){
							setApiError(res.data)
						}
						else {
							setApiError(res.data.join(','))
						}
					}
					
					setLoading(false)
				})
		}	
	}

	const renderImage = () => {
		switch(user.role){
			case 'PARENT':
				return  <img src="images/child.jpg" alt="debate-upcomming" />
			case 'TEACHER':
				return <img src="images/parent.jpg" alt="debate-upcomming" />
			default:
				return <img src="images/debate-vector-2.png" alt="debate-upcomming" />
		}
	}
	
    return(
        <PageLoader show={loading} message={'Processing....'}>
        <section className="join-debate-world ">
			<div className="container">
				<div className="row padding-l-r">
					<div className="col-12 col-lg-7">
						<div className="join-debate-img">
							<h2>Debate with anyone, anywhere. <i>For free</i>.</h2>
							<p>Build your debating skills by engaging in structured and customizable debate formats.</p>
							{renderImage()}
						</div>
					</div>
					
					<div className="col-12 col-lg-5 d-flex justify-content-center">	
						<div className="join-debate-content">
						{created ? <p className="notification success">{message}</p>: null}	
						{apiError !== ''? <p className="notification alert">{apiError}</p>: null}						
							<form action="" className="join-dob login-form">
								<div className="form-group">
									<label>Your email</label>
                                    <input onChange={(e) => setInfo({...info, 'email': e.target.value })} 
                                        type="text" placeholder="example@gmail.com" className="form-control"/>
									{errors['email'] && errors['email'].length > 0 ? <p className="alert">{errors['email'][0]}</p> : null}
								</div>
								
								{(c_name === "India") ? <div className="form-group">
									<label>Your mobile</label>
                                    <input onChange={(e) => setInfo({...info, 'mobile': e.target.value })} 
                                        type="number" placeholder="9875480015" className="form-control"/>
										{errors['mobile'] && errors['mobile'].length > 0 ? <p className="alert">{errors['mobile'][0]}</p> : null}
								</div> : null}
								
								<div className="row form-group">
									<div className="col">	
										<label>First name*</label>
										<input onChange={(e) => setInfo({...info, 'debater_first_name': e.target.value })} 
											type="text" placeholder="" className="form-control"/>
									</div>
									<div className="col">	
										<label>Middle name</label>
										<input onChange={(e) => setInfo({...info, 'debater_middle_name': e.target.value })} 
											type="text" placeholder="" className="form-control"/>
									</div>
									<div className="col">	
										<label>Last name*</label>
										<input onChange={(e) => setInfo({...info, 'debater_last_name': e.target.value })} 
											type="text" placeholder="" className="form-control"/>
									</div>	
									<div className="col-lg-12">
									{(errors['debater_first_name'] && errors['debater_first_name'].length > 0) 
										? <p className="alert">{errors['debater_first_name'][0]}</p> 
										: errors['debater_last_name'] && errors['debater_last_name'].length > 0 ?  <p className="alert">{errors['debater_last_name'][0]}</p> : null}	
									</div>
								</div>

								{age < 13 ? 
									<div className="form-group">
									<label>Your Parent or Guardian email</label>
                                    <input onChange={(e) => setInfo({...info, 'parent_email': e.target.value })} 
                                        type="text" placeholder="example@gmail.com" className="form-control"/>
									{errors['parent_email'] && errors['parent_email'].length > 0 ? <p className="alert">{errors['parent_email'][0]}</p> : null}
								</div> : null }
								
								<div className="form-group">
									<label>Create password</label>
									<p>Passwords should be at least 10 characters long and should contain a mixture of letters, numbers, and other characters.</p>
                                    <input onChange={(e) => setInfo({...info, 'password': e.target.value })} 
                                        type={show_P ? "text": "password"} placeholder="" className="form-control"/>
									{show_P ? <img src="images/eye.svg" onClick={() => setShowPassword(false)} alt=""/> : <img src="images/eye-close.svg"  onClick={() => setShowPassword(true)} alt=""/> }
									{errors['password'] && errors['password'].length > 0 ? <p className="alert">{errors['password'][0]}</p> : null}
								</div>
								
								<div className="form-group">
									<label>Confirm password</label>
                                    <input onChange={(e) => setInfo({...info, 'c_password': e.target.value })} 
                                        type={show_C ? "text": "password"} placeholder="" className="form-control"/>
									{show_C ? <img src="images/eye.svg" onClick={() => setShowC_Password(false)} alt=""/> : <img src="images/eye-close.svg"  onClick={() => setShowC_Password(true)} alt=""/> }
									{errors['c_password'] && errors['c_password'].length > 0 ? <p className="alert">{errors['c_password']}</p> : null}
								</div>
								
								<button type="button" className="btn" disabled={!(Object.keys(info).length > 0)} onClick={submit}>
									{loading ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30} /> : 'Create Account' } </button>
								
								<a className="back-btn" onClick={() => props.history.goBack()}>back</a>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
    </PageLoader>
    )
}


export default Signup