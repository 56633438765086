import React, { useState, useEffect } from 'react'

const user = { 'name': '', 'email': '' }

function  InviteAudience(props) {
    const [audience_info, setAudienceInfo] = useState(props.audience_info || [])

    const addAudience = () => {
        if(audience_info && audience_info.length < 5){
            let arr = [ ...audience_info]
            arr.push(user)
            setAudienceInfo(arr)
        }
        else {
           props.setApiError('Upto five people can be invited.')
        }
        
    }
    useEffect(() => {
        if(audience_info.length === 0 && audience_info.length < 1){
            addAudience()
        }
        props.setApiError('')
    }, [props, audience_info])


    const onChangeAudienceInfo = (key, value, index) => {
        let arr = [...audience_info]
        
        arr[index] = {...arr[index],  [key] : value }
        setAudienceInfo(arr)
        
        setTimeout(() => {
            localStorage.setItem('audience', JSON.stringify(arr))
        }, 1000);
        
    }

    const remove = (index) => {
        let arr = [...audience_info]
        arr.splice(index, 1)
        setAudienceInfo(arr)
        localStorage.setItem('audience', JSON.stringify(arr))
    }

    return(
        <div className="col-lg-6">
            <div className="row">
                <div className="col-10 ml-auto">					
                    <h2 className="text-center">Invite audience <span className="opt-line">(Upto five names/emails)<br />(Optional)</span> </h2>
                </div>
            </div>
            {audience_info.map((info, index) => {
                console.log(info, "info")
                return <div>
                    {(index > 0) ? <span onClick={() => remove(index)}>X</span> : null}
                    <div className="form-group row">
                        <label for="username" className="col-sm-2 col-form-label text-right">Name</label>
                        <div className="col-sm-10">
                            <input 
                                value={info.name || ''} 
                                type="text" 
                                className="form-control" 
                                id="username" 
                                placeholder="" 
                                onChange={(e) => onChangeAudienceInfo('name', e.target.value, index )}/>
                        </div>
                    </div>	
                    <div className="form-group row">
                        <label for="username" className="col-sm-2 col-form-label text-right">Email</label>
                        <div className="col-sm-10">
                            <input 
                                value={info.email || ''} 
                                type="text" 
                                className="form-control" 
                                id="username" 
                                onChange={(e) => onChangeAudienceInfo('email', e.target.value, index )} 
                                placeholder=""/>
                        </div>
                    </div>
                </div>
            })}
            {!(audience_info.length === 5) ? <div className="form-group row">
                <div className="col-sm-10">
                    <button className="btn invite-add-btn" type="button" onClick={addAudience}>+ Add audience</button>
                </div>
            </div> : null}         	
        </div>
    )
}

export default InviteAudience