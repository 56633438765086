import React, { useEffect, useState } from 'react'
import { Modal } from "react-responsive-modal";
import { login, socialLogin, forgotPassword } from '../../api/auth'
import { saveLoggedIn } from '../../actions/auth'
import Loader from 'react-loader-spinner'
import { validate } from '../../validations/login'
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import moment from 'moment'
import { connect } from 'react-redux';
import PageLoader from 'react-loader-advanced';
        
function LoginModal (props) {
	const [ message, setMessage] = useState('')
	const [ loading, setLoading] = useState(false)
    const [ errors, setErrors] = useState({});
    const [ info, setInfo] = useState({})
	const [ showPassword, setShowPassword ] = useState(false)
	const [ showLoading, setShowLoading] = useState(false)
	const [ apiError, setApiError] = useState('')
	const [ openModal, setOpenModal] = useState(false)
	const [successMessage, setSuccessMessage] = useState('')


    useEffect(() => { 
		setErrors([])
		setApiError('') 
		//setOpenModal(false)
	}, [info])

	const submit = () => {
		setMessage("")
		let result = validate(info)
		setErrors(result)	
		if(!(Object.keys(result).length > 0)){
			setLoading(true)
			setShowLoading(true)
			login(info)
				.then(res => {
					console.log(res, "response")
					if(res.sso_id){
						//setShowLoading(false)
						localStorage.setItem('user', JSON.stringify(res))
						//props.onClose()
						
						if(res.skipped){
							window.location = '/upcoming_debates'
						} 
						else {
							console.log(res.sso_role, "test")
							if(res.sso_role === 'PARENT'){
								window.location = '/add_child'
							}
							else if(res.sso_role === 'TEACHER'){
								window.location = '/invite_student'
							}
							else if(res.sso_role === 'DEBATER')
								window.location = '/profile'
						}
						setInfo({})	
					}
					else if(res && res.status){
						//setMessage(res.data)
						if( typeof(res.data) === 'string'){
							setApiError(res.data)
						}
						else {
							setApiError(res.data.join(','))
						}
					}
					setShowLoading(false)
					setLoading(false)
				})
				.catch(err => console.log( err, typeof(err)))
		}
	}

	const responseGoogle = (e) => {
		let info = e.profileObj || {}
		let data = {
			"dob":moment('1990-10-10').format('YYYY-MM-DD'),
			"email":info.email,
			"debater_first_name": info.givenName,   
			"debater_last_name": info.familyName,
			"account_type":"GOOGLE"
		}
		setShowLoading(true)
		socialLogin(data)
			.then (res =>  {
				if( res && res.status){
					setShowLoading(false)
					if( typeof(res.data) === 'string'){
						setApiError(res.data)
					}
					else {
						setApiError(res.data.join(','))
					}
				}
				else {		
					localStorage.setItem('user', JSON.stringify(res))
					setTimeout(() => { 
						if(res.skipped){
							window.location = '/upcoming_debates'
						} 
						else {
							if(res.sso_role === 'PARENT'){
								window.location = '/add_child'
							}
							else if(res.sso_role === 'TEACHER'){
								window.location = '/invite_student'
							}
							else if(res.sso_role === 'DEBATER')
								window.location = '/profile'
						} }, 3000);
					// setShowLoading(false)
					// props.onClose()
				}
			})	
	}

	const responseFacebook = (e) => {
		let data = {
			"dob": moment('1990-10-10').format('YYYY-MM-DD'),
			"email": e.email || '',
			"debater_first_name": e.first_name,   
			"debater_last_name": e.last_name,
			"account_type": "FACEBOOK"
		}
		setShowLoading(true)
        socialLogin(data)
            .then (res =>  {
                if(res && res.status){
					setShowLoading(false)
					//setMessage('Facebook login failed due to incomplete information')
					setApiError(res.data)
				}
				else{			
					localStorage.setItem('user', JSON.stringify(res))
					props.saveData(res)
					setTimeout(() => { 
						if(res.skipped){
							window.location = '/upcoming_debates'
						} 
						else {
							if(res.sso_role === 'PARENT'){
								window.location = '/add_child'
							}
							else if(res.sso_role === 'TEACHER'){
								window.location = '/invite_student'
							}
							else if(res.sso_role === 'DEBATER')
								window.location = '/profile'
						} 
					}, 3000);
					//setShowLoading(false)
					//props.onClose()
				}
            })
    }

	const openForgotModal = () => {
		//props.onClose()
		setOpenModal(true)
	}

	const submitForgotPassword = () => {
		setShowLoading(true)
		setLoading(true)
        forgotPassword(info)
            .then(res => {
                if(res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
						setLoading(false)
						setShowLoading(false)
                    }
                    else {
                        setApiError(res.data.join(','))
						setLoading(false)
						setShowLoading(false)
                    }
                }
                else {
					setLoading(false)
					setShowLoading(false)
					setApiError('')
                    setSuccessMessage('Please check your email for reset password link.')
                }
            })
	}
	

	const closeModal = () => {
		props.onClose()
		setInfo({})
		setOpenModal(false)
	}

    return(
        <Modal open={props.open} onClose={closeModal}>
            <h2 id="my-modal-title">{ openModal ? 'Forgot Password' : "Login" }</h2>
			<div className="modal1 login-modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<PageLoader show={showLoading} message={openModal ? 'Processing...' : 'Logging In...'}>
					{openModal ? 
						<div className="modal-content">
							<div className="modal-body">
								{successMessage !== '' ? <p className="notification success"> {successMessage}</p>: null}
								{apiError !== ''? <p className="notification alert">{apiError}</p>: null}
								<div className="join-debate-content mt-0 w-100">
									<form action="" className="join-dob login-form mt-0">
										<div className="form-group">
											<input value={info.email || ''} placeholder="Enter Email" onChange={(e) => setInfo({ 'email' : e.target.value })} />
											<img src="images/msg.svg" alt=""/>
										</div>
										<button type="button" className="btn" onClick={submitForgotPassword}>
											{loading ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30} /> : 'Submit' }
										</button>
									</form>
								</div>
							</div>
						</div> : 
						<div className="modal-content">
							<div className="modal-body">
								{apiError !== ''? <p className="notification alert">{apiError}</p>: null}
								<div className="join-debate-content mt-0 w-100">
									<form action="" className="join-dob login-form mt-0">
										<div className="form-group">
											<input value={info.email || ''} placeholder="Username or Email" onChange={(e) => setInfo({ ...info, 'email' : e.target.value })} />
											<img src="images/msg.svg" alt=""/>
											{ errors && errors['email'] ? <p className="alert"> {errors['email'][0]}</p>: null}
										</div>
										<div className="form-group mb-2">
											<input onChange={(e) => setInfo({ ...info, 'password' : e.target.value })} type={showPassword ? "text": "password"} placeholder={"Password"} className="form-control"/>
											{showPassword ? <img src="images/eye.svg" onClick={() => setShowPassword(false)} alt=""/> : <img src="images/eye-close.svg"  onClick={() => setShowPassword(true)} alt=""/> }
											{ errors && errors['password'] ? <p className="alert"> {errors['password'][0]}</p>: null}
										</div>
										{/* <div className="custom-control custom-switch">
											<input checked={rememberMe} type="checkbox" className="custom-control-input" id="customSwitch1" onChange={(e) => clickToRemember( e.target.checked)}/>
											<label className="custom-control-label mr-auto" for="customSwitch1">Remember Me</label>
										</div> */}
										<button type="button" className="btn" onClick={submit}>
											{loading ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30} /> : 'Login' }
										</button>
										{message !== '' ? <p className="alert">{message}</p>: null}
									</form>
									<div className="debate-social login-social">
										<ul>
											<span className="or">or</span>
											<li>
												<GoogleLogin
													clientId={process.env.REACT_APP_GOOGLE_CLIENT}
													buttonText="Continue with Google"
													onSuccess={responseGoogle}
													onFailure={(e) => console.log( e, "failure") }
													cookiePolicy={'single_host_origin'}
													icon="fa-google"
													redirectUri={'http://localhost:3000'}
													isSignedIn={false}
													autoLoad={false}
												/>
											</li>
											<span className="or">or</span>
											<li>
												<FacebookLogin
													buttonText="Continue with Facebook"
													appId={process.env.REACT_APP_FACEBOOK_ID}
													autoLoad={false}
													fields="first_name, last_name,email,birthday"
													callback={responseFacebook} 
													icon="fa-facebook"    
												/>
												</li>
										</ul>
									</div>
									<div className="register-section">
										<span>Don’t have an account? <a href="/signup">SIGNUP</a></span>
										<a href="#" onClick={openForgotModal} className="reset-btn">reset password</a>
									</div>
								</div>
							</div>
						</div>}
					</PageLoader>
				</div>
			</div>
		</Modal>
    )
}
        
const mapStateToProps = state => ({ ...state })

const mapDispatchToProps = dispatch => { 
	return {
		saveData : (data) => dispatch(saveLoggedIn(data))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);