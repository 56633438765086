import Header from './components/common/Header'
import Footer from './components/common/Footer'

function App(props) {
  return (
    <div className="wrapper">
        <Header/>
          {props.children}
      <Footer/>
    </div>
  );
}


export default App;
