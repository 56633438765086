import microValidator from 'micro-validator' 

export const validate = (data = {}) => {
    
    const errors = microValidator.validate({
        email: {
            required: {
                errorMsg: `Email/Username is required`
            }
        },
        password: {
            required: {
                errorMsg: `Password is required`
            }
        }
    }, data)
        
    return errors
}