import React from 'react'

function NotFound() {
    return (
        <div style={{ textAlign: 'center', margin: '50px 0' }}>
            <h3>Requested page is not available.</h3>
        </div>
    )
}

export default NotFound