import microValidator from 'micro-validator' 

export const validate = (data = {}) => {

    const errors = microValidator.validate({
        debate_title: {
            required: {
                errorMsg: `Title is required`
            }
        },
        debate_motion: {
            required: {
                errorMsg: `Motion is required`
            }
        },
        debate_language: {
            required: {
                errorMsg: `Language is required`
            }
        },
        debate_start_datetime: {
            required: {
                errorMsg: `Date is required`
            }
        },
        debate_start_time: {
            required: {
                errorMsg: `Time is required`
            }
        }, 
        debate_format_id: {
            required: {
                errorMsg: `Format is required`
            }
        }, 
        debate_proficiency_level: {
            required: {
                errorMsg: `Proficiency level is required`
            }
        }, 
        debate_type :{
            required: {
                errorMsg: `Type is required`
            }
        }

    }, data)
    
    return errors
} 