import React, { useState, useEffect }  from 'react'


function CustomAndClosed (props) {
    const [ partner_emails, setPartners] = useState(props.partnerObj || {})
    const [ opponent_emails, setOpponents] = useState(props.opponentObj || {})

    useEffect(() => {

        props.updateInfo({ ...props.info,  
            "partner_emails": Object.values(partner_emails), 
            "opponent_emails": Object.values(opponent_emails)
        })

    },[partner_emails, opponent_emails])
    

    return (
        <div className="add-fields col-lg-10 col-sm-10 col-xs-12 ml-auto">
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <input value={partner_emails['p1']} onChange={(e) => setPartners({...partner_emails, 'p1' : e.target.value })}  type='email'
                            className="form-control" placeholder="Add partner email*"/>
                    </div>
                    <div className="form-group">
                        <input value={partner_emails['p2']} onChange={(e) => setPartners({...partner_emails, 'p2' : e.target.value })} 
                            className="form-control" placeholder="Add partner email*"/>
                    </div>
                    <div className="form-group">
                        <input value={partner_emails['p3']} onChange={(e) => setPartners({...partner_emails, 'p3' : e.target.value })} 
                            className="form-control" placeholder="Add partner email*"/>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <input value={opponent_emails['O1']} onChange={(e) => setOpponents({...opponent_emails, 'O1' : e.target.value })}  
                            className="form-control" placeholder="Add opponent email*"/>
                    </div>
                    <div className="form-group">
                        <input value={opponent_emails['O2']} onChange={(e) => setOpponents({...opponent_emails, 'O2' : e.target.value })}
                            className="form-control" placeholder="Add opponent email*"/>
                    </div>
                    <div className="form-group">
                        <input value={opponent_emails['O3']} onChange={(e) => setOpponents({...opponent_emails,'O3' : e.target.value })} 
                            className="form-control" placeholder="Add opponent email*"/>
                    </div>
                    <div className="form-group">
                        <input value={opponent_emails['O4']} onChange={(e) => setOpponents({...opponent_emails, 'O4' : e.target.value })} 
                            className="form-control" placeholder="Add opponent email*"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomAndClosed