import React, { useEffect, useState } from 'react'
import { isLoggedIn } from '../../utils/api'
import { Route } from "react-router-dom";
import FirstPage from '../../containers/FirstPage'

function PrivateRoute(props) {
    const [ loggedIn, setLoginStatus] = useState(false)
    
    useEffect(() => {
        let res = isLoggedIn() ||  {}
		if(typeof(res) === 'string'){
			res = JSON.parse(res) || {};
			if(res.sso_id){
                setLoginStatus(true)
            }
        }
    }, [])
    
    if(loggedIn){
        return <Route path={props.path} component={props.component}/>
    }
    else{
        return <Route path={'/'} component={FirstPage}/> 
    }
    
}

export default PrivateRoute
