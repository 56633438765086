import React, { useState, useEffect } from 'react'
import { updateUserProfile, skipEditProfileAPI } from  '../api/auth'
import { getDebaterInfo } from '../api/debate'
import { fetchLanguagesList } from '../api/common'
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { validate } from '../validations/profile';
import { isLoggedIn } from '../utils/api';
import ChangePassword from '../components/Auth/ChangePassword';
import PageLoader from 'react-loader-advanced';
import Autocomplete from 'react-autocomplete';

function UserProfile(props){
    const [ profileFields, setProfile ] = useState({})
    const [ apiError, setApiError ] = useState('')
    const [ languages, setLanguages ] = useState([])
    const [ loading, setLoading] = useState(false)
    const [ errors, setErrors] = useState({})
    const [ userInfo, setUserInfo ] = useState({})
    const [ skipped, setSkipped ] = useState(false)
    const [ loggedInUser , setLoggedIn] = useState({})
    const [ showText, setShowText] = useState(false)
    const [ successMessage, setSuccessMessage] = useState('')
    const [ fetching, setFetching] = useState(false)
 
    useEffect(() => {
        //console.log(props.location.pathname, (props.location.pathname === '/edit_profile'), "test"  )
        let res = isLoggedIn() ||  {}
		if(typeof(res) === 'string'){
			res = JSON.parse(res) || {};
			if(res.sso_id){
                getUserInfo({ 'sso_id' : res.sso_id })
                setSkipped(res.skipped)
                setLoggedIn(res)
			}
		}
        fetchLanguagesList()
            .then(res => setLanguages(res))
       //console.log(props.loggedInUser, "loggedIn User")
       if((props.location.pathname === '/edit_profile')){
           setShowText(true)
       }

    }, [])

    const  getUserInfo = (data) => {
       // console.log( data, "data")
        setFetching(true)
        return getDebaterInfo(data)
			.then(res => {
				if( res && res.status){
					if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                        setFetching(false)
                    }
                    else {
                        setApiError(res.data.join(','))
                        setFetching(false)
                    }
				}
				else {
                    setUserInfo({ ...res, 'sso_id': data.sso_id }) 
                    setFetching(false)    
    			}
			})
	}

    const submit = () => {
        //setShowSuccess(true)
       let storageInfo = JSON.parse(localStorage.getItem('user')) || { 'sso_id': "jkjhvjhjhnb" }
       let result = validate({...userInfo, ...profileFields })
       //console.log(result, "validation")
    
       if(Object.keys(result).length > 0 ){
            setErrors(result)
        } else {
            setErrors({})
            setLoading(true)
            setFetching(true)
            updateUserProfile({...userInfo, ...profileFields, 'sso_id': storageInfo.sso_id })
                .then(res => {
                    if( res && res.status){
                        if( typeof(res.data) === 'string'){
							setApiError(res.data)
						}
						else {
							setApiError(res.data.join(','))
						}
                        setLoading(false)
                        setFetching(false)
                    }
                    else {
                        setApiError('')
                        setSuccessMessage(res)
                        setLoading(false)
                        setFetching(false)
                        props.history.push('/upcoming_debates')
                    }
                }) 
        }
    }    

    const skip = () => {
        skipEditProfileAPI({ 'sso_id': userInfo.sso_id, 'skipped': true})
            .then(res => {
                if( res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                    }
                    else {
                        setApiError(res.data.join(','))
                    }
                }
                else {
                    if(loggedInUser && loggedInUser.sso_role === 'PARENT'){
                        props.history.push('/add_child')
                    }
                    else if(loggedInUser && loggedInUser.sso_role === 'TEACHER'){
                        props.history.push('/invite_student')
                    }
                    else {
                        props.history.push('/upcoming_debates')
                    }    
                }
            })
    }
    
    return(
        <PageLoader show={fetching} message={'Processing....'}>
        <section className="join-debate-world profile-main">
			<div className="container">
				<div className="row padding-l-r pattern-bg">
                    <div className="col-12 col-lg-9 mx-auto d-flex align-items-center justify-content-center" style={{ position: 'relative', zIndex: 0 }}>
						<div className="profile_section w-100">
                            {successMessage !== '' ? <p className="notification success"> {successMessage}</p>: null}
                            {apiError !== '' ? <p className="notification alert"> {apiError}</p>: null}
							<div className="profile-inner">
								{skipped || showText ?  <h2 className="text-center">Edit your profile</h2>: <h2 className="text-center">You are almost ready.<br />
									<span>Complete your profile now.</span>
								</h2>}
								<form>
									<div className="scroll_box">
                                        <div className="form-group row">
                                            <label for="bio" className="col-sm-4 col-form-label text-right">Bio<sup>*</sup></label>
                                            <div className="col-sm-8">
                                                <textarea onChange={(e) => setProfile({ ...profileFields, 'debater_bio': e.target.value })}  
                                                    colSpan="5" type="text" 
                                                    value={ profileFields.debater_bio ?  profileFields.debater_bio : userInfo.debater_bio} 
                                                    className="form-control" id="bio" placeholder="Debater Bio"></textarea>
                                                <small>Tell the DebateNow community about yourself in 160 words or less.</small>
                                                {errors['debater_bio'] && errors['debater_bio'].length > 0 ? <p className="alert">{errors['debater_bio'][0]}</p> : null}
                                            </div>         
                                        </div>
										<div className="form-group row">
											<label for="language" className="col-sm-4 col-form-label text-right">Primary/Secondary<sup>*</sup></label>
                                            <div className="col-sm-8">
                                                <div className="divide">
                                                    {/* <Autocomplete
                                                        getItemValue={(item) => item.label}
                                                        items={languages}
                                                        renderItem={(item, isHighlighted) =>
                                                            <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                                            {item.label}
                                                            </div>
                                                        }
                                                        value={profileFields.debater_primary_language ? profileFields.debater_primary_language : userInfo.debater_primary_language}
                                                        onChange={(e) => setProfile({ ...profileFields, 'debater_primary_language': e.target.value })}
                                                        onSelect={(val) => console.log(val, "val")}
                                                    /> */}
                                                    <select placeholder="Primary Language"
                                                        name="primary" className="form-control" id="primary1" value={profileFields.debater_primary_language ? profileFields.debater_primary_language : userInfo.debater_primary_language} onChange={(e) => setProfile({ ...profileFields, 'debater_primary_language': e.target.value })}>
                                                        <option value="">Primary Language</option>
                                                        {languages && (languages.length > 0 ) ? languages.map(lang => <option value={lang.id}>{lang.language_name}</option>) : null}
                                                    </select>
                                                    <select placeholder="Secondary Language"
                                                        name="secondary" className="form-control" id="secondary1" value={profileFields.debater_secondry_language ? profileFields.debater_secondry_language : userInfo.debater_secondry_language } onChange={(e) => setProfile({ ...profileFields, 'debater_secondry_language': e.target.value })}>
                                                        <option value="">Secondary Language</option>
                                                        {languages && (languages.length > 0 ) ? languages.map(lang => <option value={lang.id}>{lang.language_name}</option>) : null}
                                                    </select>
                                                </div>
                                                {errors['debater_primary_language'] && errors['debater_primary_language'].length > 0 ? <p className="alert">{errors['debater_primary_language'][0]}</p> : null}
                                                {errors['debater_secondry_language'] && errors['debater_secondry_language'].length > 0 ? <p className="alert">{errors['debater_secondry_language'][0]}</p> : null}
                                            </div>
                                        </div>								
                                        {/* <div className="form-group row mb-0">
                                            <label for="account" className="col-sm-4 col-form-label text-right">Add Zoom Pro account<sup>*</sup></label>
                                            <div className="col-sm-8 zomm-account">
                                                <div className="row">
                                                    <div className="col-lg-12 col-12">
                                                        <div className="form-group">
                                                            <input onChange={(e) => setProfile({ ...profileFields, 'debater_zoom_link' : e.target.value })} 
                                                                type="text"  defaultValue={profileFields.debater_zoom_link ? profileFields.debater_zoom_link : userInfo.debater_zoom_link} className="form-control" id="" placeholder="Zoom pro account URL"/>
                                                        {errors['debater_zoom_link'] && errors['debater_zoom_link'].length > 0 ? <p className="alert">{errors['debater_zoom_link'][0]}</p> : null}
                                                     </div>
                                                    </div>
                                                   
                                                    <div className="col-lg-12 col-12">
                                                        <div className="form-group mb-0">
                                                            <span> <a href="https://zoom.us/signup" target="/blank">Create Zoom account </a></span>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* <!-- <div className="form-group mb-0">
                                                    <input type="text" className="form-control" id="" placeholder="Take DebateNow Pro account  (Zoom included)"/>
                                                </div> -->  
                                            </div>
                                        </div>  */}
									</div>	
																				
                                    <div className="row">
                                        <div className="col-sm-8 col-xs-12 ml-auto">
                                            <div className="divide">
                                            <button onClick={submit} className="btn" type="button" disabled={!(Object.keys(profileFields).length > 0 )}>
                                                {loading ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30} /> : 'Submit' } 
                                            </button>
                                            {skipped ? 
                                                <button 
                                                    onClick={() => props.history.push('/upcoming_debates')}
                                                    className="btn" type="button">Skip
                                                </button>
                                            :<button 
                                                onClick={skip}
                                                className="btn" type="button">Skip
                                            </button>}
                                            </div>
                                        </div>												
                                    </div>	
                                    <div className="row">
                                        <div className="col-sm-8 col-xs-12 ml-auto">
                                            {loggedInUser.sso_role === 'PARENT'? 
                                                <button 
                                                    onClick={() => props.history.push('/add_child')}
                                                    className="btn" type="button">Invite Child
                                                </button> : null}
                                            {loggedInUser.sso_role === 'TEACHER'?
                                                    <button 
                                                        onClick={() => props.history.push('/invite_student')}
                                                        className="btn" type="button">Invite Student
                                                    </button>: null}
                                        </div>
                                    </div>								
                                    <div className="row">
                                        <div className="col-sm-8 col-xs-12 ml-auto">
                                            <a  className="back-btn" onClick={() => props.history.goBack()}>back</a>
                                        </div>
                                    </div>
								</form>
							</div>
                            {showText ? 
                                <ChangePassword 
                                    setApiError={setApiError}
                                    setSuccessMessage={setSuccessMessage}
                                    id={userInfo.sso_id}  next={() => props.history.push('/upcoming_debates')}/> : null }
						</div>
                        
					</div>
				</div>
			</div>
		</section>
    </PageLoader>)
}

const mapStateToProps = state => {
	return {
		loggedInUser : state.authReducer.loggedInUser 
	}
}

export default connect(mapStateToProps)(UserProfile);