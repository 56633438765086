import React, { useEffect, useState } from "react";
import AddNewChild from '../components/Child/AddChild';
import Loader from 'react-loader-spinner'
import { addChildAPI, skipEditProfileAPI } from '../api/auth'
import { toast } from 'react-toastify'
import { isLoggedIn } from '../utils/api'
import PageLoader from 'react-loader-advanced'

const fields = {
    'username' : '', 
    'debater_first_name': '', 
    'dob': '', 
    'school': '', 
    'topics': ''
}

function AddChild(props) {
    const [ children, setChildren] = useState(props.childList || [])
    const [ userInfo, setUserInfo] = useState()
    const [ loading, setLoading] = useState(false)
    const [ parent , setParent] = useState('')
    const [ apiError, setApiError] = useState('')
    const [ apiSuccess, setApiSuccess] = useState('')

    const addMore = () => {
        let arr = [...children]
        arr.push(fields)
        setChildren(arr)
    }

    useEffect(() => {
        let res = isLoggedIn() ||  {}
		if(typeof(res) === 'string'){
			res = JSON.parse(res) || {};
            if(res.sso_id){
                setParent({ 'parent_id': res.sso_id })
                setUserInfo(res)
            }
        } else {
            setApiError('Please login before adding children')
        }
        if( children.length === 0 && children.length < 10){
            addMore()
        }
    }, [])


    const submit = () => {
        setLoading(true)
        if(children.length > 0){
            let verify = children.map(child => {
                let arr = Object.values(child)
                let filtered = arr.filter(item => item) || []
                if(filtered.length > 0){
                    return child
                }
                else {
                    return false
                }
            })
            ///console.log(verify, verify.includes(false), "verify")
            if(verify.includes(false)){
                setLoading(false)
               return setApiError('Please fill child info.')
            }
            else {
                addChildAPI({...parent, 'childs': [...children]})
                    .then(res => {
                        if(res && res.status){
                            if( typeof(res.data) === 'string'){
                                setApiError(res.data)
                                setLoading(false)
                            }
                            else {
                                setApiError(res.data.join(','))
                                setLoading(false)
                            }
                        }
                        else {	
                            setLoading(false)
                            localStorage.setItem('child', 'true')
                            setApiError('')
                            setApiSuccess('The child has been added and an email has also been sent.')
                            setTimeout(() => {
                                props.history.push('/thanks')
                            }, 2000) 
                        }
                    })
            }
        }
    }





    const skip = () => {
        localStorage.setItem('child', 'false')
        props.history.push('/thanks')
    }


    
    return(
        <PageLoader show={loading} message={'Processing'}>
        <section className="join-debate-world profile-main">
			<div className="container">
				<div className="row padding-l-r pattern-bg">
					<div className="col-12 col-lg-10 mx-auto d-flex align-items-center justify-content-center" style={{ position: 'relative', zIndex: 0 }}>
						<div className="profile_section w-100">
                        {apiSuccess !== ''? <p className="notification success">{apiSuccess}</p>: null}
                        {apiError !== ''? <p className="notification alert">{apiError}</p>: null}
							<div className="profile-inner">
								<h2 className="text-center">Invite child</h2>
                                <form>
                                    {children.map((child, index) => <AddNewChild info={child} srNo={index + 1} 
                                        setMultiple={setChildren} children={children} saveIndex={index + 1}/>)}
                                    <div className="col-12">										
                                        <div className="row">
                                            <div className="col-sm-4"></div>
                                            <div className="col-sm-8 ">
                                                <div className="row">
                                                    <div className="col-6 mx-auto">  
                                                        <button className="btn add-more" type="button" onClick={addMore}>+ Add More</button>
                                                        <button className="btn" type="button" onClick={submit}>
                                                        {loading ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30} /> : 'Submit' }</button>
                                                        {userInfo && userInfo.skipped ? 
                                                            <button className="btn" onClick={() => props.history.push('/upcoming_debates')}>Skip</button>
                                                        :<button 
                                                            onClick={skip}
                                                            className="btn" type="button">Skip
                                                        </button>}
                                                    </div>
                                                </div>
                                            </div>												
                                        </div>									
                                        <div className="row">
                                            <div className="col-sm-4"></div>
                                            <div className="col-sm-8">
                                                <a className="back-btn" onClick={() => props.history.goBack()}>back</a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </PageLoader>
    )
}

export default AddChild