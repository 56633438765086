import microValidator from 'micro-validator' 

export const validate = (data) => {

    const errors = microValidator.validate({
        email: {
            required: {
                errorMsg: `Email is required`
            },
            email: {
                errorMsg: `Enter a valid email`
            }
        },
        password: {
            required: {
                errorMsg: `Password is required`
            }
        }, 
        debater_first_name: {
            required: {
                errorMsg: `First Name and Last Name is required`
            }
        }, 
        debater_last_name: {
            required: {
                errorMsg: `First Name and Last Name is required`
            }
        }
    }, data)

    return errors
}
