import React, { useEffect, useState } from 'react'
import Accordion from '../components/debate/AcceptDebateAccordion'
import { 
    getSavedDebatesList, 
    acceptDebate, 
    declineDebate,
    getDebaterInfo,  
    fetchOpenCloseDebates } from '../api/debate'
import { toast } from 'react-toastify';
import PageLoader from 'react-loader-advanced';

function AccpetAll (props) {
    const [debates, setOpenDebates] = useState([])
    const [ user, setUser] = useState({})
    const [loading, setLoading] = useState(false)
    const [fetching , setFetching] = useState(false)
    const [closedebates, setCloseDebates] = useState([])
    const [userInfo, setDebaterInfo] = useState({})
    const [apiError, setApiError] = useState('')
    const [apiSuccess, setApiSuccess] = useState('')

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user')) || {}
        if( user && user.sso_id){
            setFetching(true)
            getUserInfo({ 'sso_id' : user.sso_id})
            getDebates({ 'email': user.sso_email, 'status' : 'OPEN' })
            getDebates({ 'email': user.sso_email, 'status' : 'CLOSED' })
        }   
    }, [])
    
    
    const back = () => {
        props.history.goBack()
    }

    const getUserInfo = (data) => {
        getDebaterInfo(data)
            .then(res => {
                if( res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                    }
                    else {
                        setApiError(res.data.join(','))
                    }
                }
                else {
                    setDebaterInfo(res)
                }
            })
    }

    const getDebates = (data) => {
        setFetching(true)
        setOpenDebates([])
        fetchOpenCloseDebates(data)
            .then(res => {
                if( res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                        setFetching(false)
                    }
                    else {
                        setApiError(res.data.join(','))
                        setFetching(false)
                    }
                }
                else {
                    let arr = res.filter(res => !(res.accepted_by || res.declined_by )) || []
                    if(data.status === 'OPEN'){
                        setOpenDebates(arr)
                    } 
                    else if(data.status === 'CLOSED'){
                        setCloseDebates(arr)
                    }
                    setFetching(false)
                }
            })
    } 


    const accept = (debateId) => {
        setFetching(true)
        acceptDebate({ 'userID': user.sso_id, 'debateID': debateId })
            .then(res => {
                if(res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                        setFetching(false)
                    }
                    else {
                        setApiError(res.data.join(','))
                        setFetching(false)
                    }
                }
                else {
                    setApiSuccess(res)
                    setFetching(false)
                    //getSavedDebatesList({ 'sso_id': user.sso_id })
                    setTimeout(() => {
                        props.history.push('/upcoming_debates')
                    }, 1000);
                }
            })
    }

    const decline = (debateId) => {
        console.log( debateId, user)
        setFetching(true)
        declineDebate({ 'userID': user.sso_id, 'debateID': debateId })
            .then(res => {
                if(res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                        setFetching(false)
                    }
                    else {
                        setApiError(res.data.join(','))
                        setFetching(false)
                    }
                }
                else {
                    setApiSuccess(res)
                    setFetching(false)
                    setTimeout(() => {
                        props.history.push('/upcoming_debates')
                    }, 1000);
                }
            })
    }

    return(
        <PageLoader show={fetching} message={'Loading.....'}>
            <section className="accept_debate join-debate-world">
                <section className="container">
                {apiSuccess !== ''? <p className="notification success">{apiSuccess}</p>: null}
                {apiError !== ''? <p className="notification alert">{apiError}</p>: null}
                    <div className="row padding-l-r pattern-bg">
                        <div className="col-12 col-lg-12 mx-auto" style={{ position: 'relative', zIndex: 0 }}>
                            <h2 className="text-center">Accept debate</h2>
                            {debates && (debates.length > 0) ? 
                            <div className="main">	
                                <h4>Open Challenges</h4>	
                                    <div className="row owl-item">
                                    {debates.map((debate, index) => <Accordion debate={debate} accept={accept} decline={decline} userInfo={userInfo}/> ) }
                                        <div className="slide_btn d-flex">
                                            <ul className="d-flex ml-auto">
                                                <li>
                                                    <button className="btn" onClick={back}><strong>Back</strong></button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>	       
                                </div> : !fetching && (debates.length === 0) ?  <p>No Open Debates Found </p> : ''}
                                {closedebates && (closedebates.length > 0) ? 
                                    <div className="main">	
                                        <h4>Closed Challenges</h4>	
                                        <div className="row owl-item">
                                            {closedebates.map((debate, index) => <Accordion debate={debate} accept={accept} decline={decline} userInfo={userInfo}/> ) }
                                                <div className="slide_btn d-flex">
                                                    <ul className="d-flex ml-auto">
                                                        <li>
                                                            <button className="btn" onClick={back}><strong>Back</strong></button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>	       
                                        </div> : !fetching && (debates.length === 0) ?  <p>No Closed Debates Found </p> : ''}
                            </div> 
                        </div>
                </section>
            </section>
        </PageLoader>

    )
}
export default AccpetAll