import React, { useEffect, useState } from 'react'
import Carousel  from 'react-slick';
import AcceptDebateSliderItem from  "../components/debate/AcceptDebateSlideItem";
import { 
    getSavedDebatesList, 
    acceptDebate, 
    declineDebate, 
    fetchOpenCloseDebates, 
    getDebaterInfo
} from '../api/debate'
import PageLoader from 'react-loader-advanced';

function AcceptDebates (props) {
    const [debates, setOpenDebates] = useState([])
    const [user, setUser] = useState({})
    const [userInfo, setDebaterInfo] = useState({})
    const [fetching, setFetching] = useState(false)
    const [closedebates, setCloseDebates] = useState([])
    const [apiError, setApiError] = useState('')
    const [apiSuccess, setApiSuccess] = useState('')

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user')) || {}
        if( (user && user.sso_id)){
            setUser(user)
           // console.log( user, "user ")
            getUserInfo({ 'sso_id' : user.sso_id})
            getDebates({ 'email': user.sso_email, 'status': 'OPEN' })
            getDebates({ 'email': user.sso_email, 'status': 'CLOSED' })
        }   
    }, [])
    
    const seeAll = () => {
        props.history.push('/accept_all')
    }

    const back = () => {
        props.history.goBack()
    } 

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,    
        slidesToScroll: 1, 
        arrows: true, 
        autoplay: true,
    };

    const getUserInfo = (data) => {
        getDebaterInfo(data)
            .then(res => {
                if( res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                    }
                    else {
                        setApiError(res.data.join(','))
                    }
                }
                else {
                    setDebaterInfo(res)
                }
            })
    }
 
    const getDebates = (data) => {
        setFetching(true)
        setOpenDebates([])
        fetchOpenCloseDebates(data)
            .then(res => {
                if( res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                        setFetching(false)
                    }
                    else {
                        setApiError(res.data.join(','))
                        setFetching(false)
                    }
                }
                else {
                    setFetching(false)
                    let arr = res.filter(data => !(data.accepted_by || data.declined_by )) || []
                    if(data.status === 'OPEN'){
                        setOpenDebates(arr)
                    }
                    else if( data.status === 'CLOSED'){
                        setCloseDebates(arr)
                    }
                }
                
            })
    } 


    const accept = (debateId) => {
        //console.log( debateId, user)
        setFetching(true)
        acceptDebate({ 'userID': user.sso_id, 'debateID': debateId })
            .then(res => {
                if(res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                        setFetching(false)
                    }
                    else {
                        setApiError(res.data.join(','))
                        setFetching(false)
                    }
                }
                else {
                    setApiSuccess('Accepted successfully')
                    setFetching(false)
                    setTimeout(() => {
                        props.history.push('/upcoming_debates')
                    }, 1000);
                }
            })
    }
    
    const decline = (debateId) => {
       // console.log( debateId, user)
        setFetching(true)
        
        declineDebate({ 'userID': user.sso_id, 'debateID': debateId })
            .then(res => {
                if(res && res.status){
                    if( typeof(res.data) === 'string'){
                        setApiError(res.data)
                        setFetching(false)
                    }
                    else {
                        setApiError(res.data.join(','))
                        setFetching(false)
                    }
                }
                else {
                    setApiSuccess(res)
                    setFetching(false)
                    setTimeout(() => {
                        props.history.push('/upcoming_debates')
                    }, 1000);
                }
            })
    }
    
    let openDebatesView = (debates !== undefined) && (debates.length < 3) ? debates.length : 3
    let closeDebatesView = (closedebates !== undefined) && (closedebates.length < 3) ? closedebates.length : 3 
      
    return(
        <PageLoader show={fetching} message="Loading....">
            <section className="accept_debate join-debate-world">
                <div className="container">
                {apiSuccess !== ''? <p className="notification success">{apiSuccess}</p>: null}
                {apiError !== ''? <p className="notification alert">{apiError}</p>: null}
                    <div className="row padding-l-r pattern-bg">
                        <div className="col-12 col-lg-12 mx-auto" style={{ position: 'relative', zIndex: 0 }}>
                            <h2 className="text-center">Accept Debate</h2>
                            {(debates.length > 0) ? 
                                <div className="main">							
                                    <h4>Open Challenges</h4>
                                    <Carousel {...{...settings, 'slidesToShow': openDebatesView }}>  
                                        {debates.map((debate, index )=> <AcceptDebateSliderItem debate={debate} accept={accept} decline={decline} userInfo={user}/>)}
                                    </Carousel>
                                    <div className="slide_btn d-flex">
                                        <ul className=" d-flex ml-auto">
                                            <li>
                                                <button className="btn" onClick={seeAll}>See all</button>
                                            </li>
                                            <li>
                                                <button className="btn" onClick={back}>Back</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>  :  !fetching && (debates.length === 0) ?  <p> No Open Debates Found.</p> : ''}
                                {(closedebates.length > 0) ? 
                                <div className="main">							
                                    <h4>Direct Challenges</h4>
                                    <Carousel {...{...settings, 'slidesToShow': closeDebatesView }}>  
                                        {closedebates.map((debate, index )=> <AcceptDebateSliderItem debate={debate} accept={accept} decline={decline} userInfo={userInfo} />)}
                                    </Carousel>
                                    <div className="slide_btn d-flex">
                                        <ul className=" d-flex ml-auto">
                                            <li>
                                                <button className="back_btn" onClick={seeAll}>See all</button>
                                            </li>
                                            <li>
                                                <button className="back_btn" onClick={back}>Back</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>  :  !fetching && (closedebates.length === 0) ?  <p>No Direct Debates Found</p> : ''}
                        </div>
                    </div>
                </div>
            </section>
        </PageLoader>
    )
}

export default AcceptDebates